import React from 'react';
import { Tag, Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
export function OrderStatusTracking({ detailStatus }) {
	const { t, i18n } = useTranslation();

	return (
		<Timeline mode="left">
			{detailStatus?.length ? (
				detailStatus.map((event, index) => (
					<Timeline.Item key={index} label={event.dateTime}>
						<div>
							{event.fleetName}-{event.employeeName}:
							<Tag color="default">
								{t('orderStatus.' + event.orderStatusCode + '.name')}
							</Tag>
						</div>
					</Timeline.Item>
				))
			) : (
				<Timeline.Item>
					{t('orders.orderDetails.No tracking information available')}
				</Timeline.Item>
			)}
		</Timeline>
	);
}
