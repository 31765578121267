import React from 'react';
import quick from '../img/quick_reference.svg';
import quickSelected from '../img/quick_reference_selected.svg';

export function ReferenceHistory({
	references,
	selectedReference,
	onSelectReference,
}) {
	return (
		<div className="reference-list">
			{references.map(({ reference, order }) =>
				order ? (
					<div
						key={reference}
						className={`reference-item ${
							selectedReference === reference ? 'selected' : ''
						}`}
						onClick={() => onSelectReference(reference)}
					>
						<img
							src={reference === selectedReference ? quickSelected : quick}
							alt="quick"
						/>
						<span>{reference}</span>
						<p>{order.currentStatus}</p>
						<p>
							{order.origin} ➡ {order.destination}
						</p>
					</div>
				) : (
					<div key={reference} className="reference-item invalid">
						{reference} - {'Referencia no encontrada'}
					</div>
				)
			)}
		</div>
	);
}
