import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button } from 'antd';
import { API_URL_ORDER_FORECAST, get } from '../../App/Api';
import './main.css';
import { PerpageForecast } from './PerPageForecast';
import { AccordeonFiltersForecast } from './AccordeonForecast/AccordeonFiltersForecast';
import { useTranslation } from 'react-i18next';

export function Forecast({ userDetail }) {
	const { t, i18n } = useTranslation();

	const [forecast, setForecast] = useState([]);
	const [forecastPerPage, setForecastPerPage] = useState(10);
	const [forecastPage, setForecastPage] = useState(1);

	// const [clientIds, setClientIds] = useState([]);
	const [postalCodes, setPostalCodes] = useState('');
	const [cities, setCities] = useState('');
	const [states, setStates] = useState('');

	const [endDateReceived, setEndDateReceived] = useState('');
	const [startDateReceived, setStartDateReceived] = useState('');

	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const [loading, setLoading] = useState(false);

	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData();
	}, [forecastPerPage, forecastPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = `${API_URL_ORDER_FORECAST}?perPage=${forecastPerPage}&page=${forecastPage}`;

			// if (clientIds.length) {
			// 	url += `&clientIds=${clientIds.join(',')}`;
			// }

			if (startDateReceived) {
				url += `&startDateReceived=${startDateReceived}`;
			}

			if (endDateReceived) {
				url += `&endDateReceived=${endDateReceived}`;
			}

			if (states.length) {
				url += `&states=${states}`;
			}

			if (cities.length) {
				url += `&cities=${cities}`;
			}

			if (postalCodes.length) {
				url += `&postalCodes=${postalCodes}`;
			}

			const response = await get(url);

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === forecastPerPage);
				setForecast(response.data);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const loadMoreForecast = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setForecastPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (forecastPage > 1) {
			setForecastPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setForecastPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const columns = [
		{
			title: t('postalCode'),
			dataIndex: 'postalCode',
			key: 'postalCode',
			align: 'center',
		},
		{
			title: t('city'),
			dataIndex: 'city',
			key: 'city',
			align: 'center',
		},
		{
			title: t('state'),
			dataIndex: 'state',
			key: 'state',
			align: 'center',
		},
		{
			title: t('qty'),
			dataIndex: 'qty',
			key: 'qty',
			align: 'center',
			render: (text) => text || '0',
		},
	];

	const filterProps = {
		// FILTERS
		setStates,
		setCities,
		setPostalCodes,
		// setClientIds,
		setEndDateReceived,
		setStartDateReceived,

		handleSearch: handleSearch,
	};

	return (
		<div className="forecast-chart">
			<div className="filters">
				<form onKeyDown={handleKeyPress}>
					<AccordeonFiltersForecast filterProps={filterProps} />
				</form>
			</div>
			{loading ? (
				<Spin size="large" />
			) : error ? (
				<Alert message="Error" description={error} type="error" showIcon />
			) : (
				<>
					<div className="employee-footer">
						<Button onClick={handlePreviousPage} disabled={forecastPage === 1}>
							{t('previousPage')}
						</Button>
						<Button
							onClick={loadMoreForecast}
							disabled={!hasMorePages || isLoadingMore}
						>
							{t('nextPage')}
						</Button>
						<PerpageForecast
							forecastPerPage={forecastPerPage}
							setForecastPerPage={setForecastPerPage}
						/>
					</div>
					<div className="forecast-table">
						<Table
							dataSource={forecast}
							columns={columns}
							rowKey="fleetId"
							pagination={false}
						/>
					</div>
				</>
			)}
		</div>
	);
}
