import React from 'react'
import './main.css'

export function Dashboard() {
    return (
        <>
        <div className="statistics-container">
            <div className="statistic-item">
                <div className="circle">
                    <span className="value">150</span>
                </div>
                <div className="info">
                    <span className="label">Orders</span>
                    <div className="bar-container">
                        <div className="bar" style={{ width: '75%', backgroundColor: '#ff6b6b' }}></div>
                    </div>
                </div>
            </div>
            <div className="statistic-item">
                <div className="circle">
                    <span className="value">120</span>
                </div>
                <div className="info">
                    <span className="label">Delivered</span>
                    <div className="bar-container">
                        <div className="bar" style={{ width: '60%', backgroundColor: '#48dbfb' }}></div>
                    </div>
                </div>
            </div>
            <div className="statistic-item">
                <div className="circle">
                    <span className="value">30</span>
                </div>
                <div className="info">
                    <span className="label">Pending</span>
                    <div className="bar-container">
                        <div className="bar" style={{ width: '15%', backgroundColor: '#ff9f43' }}></div>
                    </div>
                </div>
            </div>
            <div className="statistic-item">
                <div className="circle">
                    <span className="value">10</span>
                </div>
                <div className="info">
                    <span className="label">Returned</span>
                    <div className="bar-container">
                        <div className="bar" style={{ width: '5%', backgroundColor: '#1dd1a1' }}></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
