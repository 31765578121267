import React, { useEffect, useState } from 'react';
// import imgWorld from './img/world.svg';
// import './main.css';
import orders from '../img/orders.svg';
import picking from '../img/orders.svg';
import picking_box from '../img/orders.svg';
import routes from '../img/orders.svg';
import forecast from '../img/orders.svg';
import arrow_up from '../img/arrow_up.svg';
import employee from '../img/employees.svg';
import fleets from '../img/fleets.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	AreaChartOutlined,
	BorderOuterOutlined,
	CodeSandboxOutlined,
	ColumnWidthOutlined,
	CompassOutlined,
	ScanOutlined,
	UnorderedListOutlined,
} from '@ant-design/icons';
// import { saveToLocalStorage } from '../Dashboard/Login/storage';

export const BarMobile = ({ setPageTitle, roleId }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('');
	const navigate = useNavigate();
	const location = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [arrow, setArrow] = useState(false);

	roleId = localStorage.getItem('roleId');

	useEffect(() => {
		const hash = location.hash;
		setActiveTab(hash);
		setPageTitle(getPageTitleFromHash(hash));
	}, [location, setPageTitle]);

	const getPageTitleFromHash = (hash) => {
		return 'control.' + hash.replace('#', '');
	};

	const handleTabClick = (tab) => {
		const cleanTab = tab.endsWith('#') ? tab.slice(0, -1) : tab;
		const currentHash = window.location.hash;

		if (cleanTab === currentHash) {
			return;
		}

		const pageTitle = getPageTitleFromHash(cleanTab);

		setActiveTab(cleanTab);
		setPageTitle(pageTitle);
		navigate(cleanTab);
	};

	const handleToggle = () => {
		setIsOpen(!isOpen);
		setArrow(!arrow);
	};

	return (
		<section className="bar_mobile">
			<div className={`mobile-accordion ${isOpen ? 'open' : ''}`}>
				<div className="mobile-header" onClick={handleToggle}>
					<img
						className={`mobile-icon ${arrow ? 'rotate' : ''}`}
						src={arrow_up}
						alt="arrow"
					/>
				</div>
				<div className="mobile-content" style={{ zIndex: 1000 }}>
					{/* <figure
				className={`menu__image ${
					activeTab === '#dashboard' ? 'selected' : ''
				}`}
				onClick={() => handleTabClick('#dashboard')}
			>
				<img src={dashboard} alt="dashboard" />
				<p>{t('control.dashboard')}</p>
			</figure> */}
					<figure
						className={`menu__image ${
							activeTab === '#orders' ? 'selected' : ''
						}`}
						onClick={() => handleTabClick('#orders')}
					>
						<UnorderedListOutlined style={{ color: 'white' }} />
						<p>{t('control.orders')}</p>
					</figure>

					{!['2'].includes(roleId) && (
						<>
							<figure
								className={`menu__image ${
									activeTab === '#picking' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#picking')}
							>
								<ScanOutlined style={{ color: 'white' }} />
								<p>{t('control.picking')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#picking_box' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#picking_box')}
							>
								<CodeSandboxOutlined style={{ color: 'white' }} />
								<p>{t('control.picking_box')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#following' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#following')}
							>
								<CompassOutlined style={{ color: 'white' }} />
								<p>{t('control.following')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#routes' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#routes')}
							>
								<BorderOuterOutlined style={{ color: 'white' }} />
								<p>{t('control.routes')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#forecast' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#forecast')}
							>
								<AreaChartOutlined style={{ color: 'white' }} />
								<p>{t('control.forecast')}</p>
							</figure>
						</>
					)}

					{['3'].includes(roleId) && (
						<>
							{/* <figure
						className={`menu__image ${
							activeTab === '#organization' ? 'selected' : ''
						}`}
						onClick={() => handleTabClick('#organization')}
					>
						<img src={organizacion} alt="organization" />
						<p>{t('control.organization')}</p>
					</figure>
					<figure
						className={`menu__image ${
							activeTab === '#users' ? 'selected' : ''
						}`}
						onClick={() => handleTabClick('#users')}
					>
						<img src={user} alt="user" />
						<p>{t('control.users')}</p>
					</figure> */}
							<figure
								className={`menu__image ${
									activeTab === '#mrw' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#mrw')}
							>
								<ColumnWidthOutlined style={{ color: 'white' }} />
								<p>{t('control.mrw')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#mrw_multiple' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#mrw_multiple')}
							>
								<ColumnWidthOutlined style={{ color: 'white' }} />
								<p>{t('control.mrw_multiple')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#label_multiple' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#label_multiple')}
							>
								<ColumnWidthOutlined style={{ color: 'white' }} />
								<p>{t('control.label_multiple')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#employees' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#employees')}
							>
								<img src={employee} alt="employee" />
								<p>{t('control.employees')}</p>
							</figure>
							<figure
								className={`menu__image ${
									activeTab === '#fleets' ? 'selected' : ''
								}`}
								onClick={() => handleTabClick('#fleets')}
							>
								<img src={fleets} alt="fleets" />
								<p>{t('control.fleets')}</p>
							</figure>
						</>
					)}
					{/* <figure className="menu__logout">
				<Logout />
			</figure> */}
				</div>
			</div>
		</section>
	);
};
