import React from "react";
import { Logout } from "../Dashboard/Logout";
import back from "./img/arrow_back.svg"
import './main.css';

export function Account({ userDetail, handleLogout, close }) {

  return (
    <aside className="account-container">
    <div className="account-head">
        <img src={back} alt="arrowBack" onClick={close}/>
        <h1>Account settings</h1>
    </div>
      <div className="account-card">
        {userDetail ? (
          <div>
            <p className="account-name">{userDetail.nickname}</p>    
            <p className="account-email">{userDetail.email}</p>
            <p className="account-location">{userDetail.timeZone}</p>
          </div>
        ) : (
          <p>No user data available</p>
        )}
        <Logout onLogout={handleLogout}/>
      </div>
    </aside>
  );
}
