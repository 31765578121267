import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function PerPagefilter({ perPage, setperPage }) {
	const { t } = useTranslation();

	const handlePerPageChange = (value) => {
		setperPage(value);
	};

	return (
		<Select
			onChange={handlePerPageChange}
			value={perPage}
			style={{ width: '20%', margin: '10px 0' }}
		>
			<Option value={10}>10</Option>
			<Option value={20}>20</Option>
			<Option value={50}>50</Option>
			<Option value={100}>100</Option>
		</Select>
	);
}
