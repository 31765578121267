import React from 'react';
import { Space, DatePicker } from 'antd';
import moment from 'moment';

export const DateFilter = ({ setDate, date }) => {
	function handleChange(date, dateString) {
		let newDate;
		if (date) {
			newDate = moment(date?.$d).format('YYYY-MM-DD');
		} else {
			date = null;
		}
		setDate(newDate);
	}

	return (
		<DatePicker
			onChange={handleChange}
			style={{ width: '100%', margin: '10px 0' }}
			defaultValue={date}
		/>
	);
};
