import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL_USERS, get, post } from '../../../App/Api';
import { Select } from 'antd';
import { timeZones } from '../TimeZone';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export function Add({ setShowAddForm, onClose }) {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [newUser, setNewUser] = useState({
		code: '',
		nickname: '',
		password: '',
		email: '',
		timeZone: '',
		active: false,
	});

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await get(API_URL_USERS);
			setUsers(response.data);
		} catch (error) {
			setError(error.response.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [i18n.language]);

	const handleChangeUser = (event) => {
		const { name, value } = event.target;
		setNewUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleTimeZoneChange = (value) => {
		setNewUser((prevUser) => ({
			...prevUser,
			timeZone: value,
		}));
	};

	const saveNewUser = async () => {
		try {
			await post(API_URL_USERS, newUser);

			fetchData();
			setShowAddForm(false);
			setNewUser({
				code: '',
				nickname: '',
				password: '',
				email: '',
				timeZone: '',
				active: false,
			});
			navigate('/dashboard');
		} catch (error) {
			console.error('Error al guardar los cambios:', error);
		}
	};

	return (
		<div className="add-user-container">
			<h2>{t('users.add.Add-User')}</h2>
			<form className="add-form">
				<label htmlFor="code">{t('users.add.Code')}</label>
				<input
					type="text"
					name="code"
					value={newUser.code}
					onChange={handleChangeUser}
					placeholder={t('users.add.Code')}
				/>
				<label htmlFor="nickname">{t('users.add.Nickname')}</label>
				<input
					type="text"
					name="nickname"
					value={newUser.nickname}
					onChange={handleChangeUser}
					placeholder={t('users.add.Nickname')}
				/>
				<label htmlFor="password">{t('users.add.Password')}</label>
				<input
					type="password"
					name="password"
					placeholder={t('users.add.Password')}
					value={newUser.password}
					onChange={handleChangeUser}
				/>
				<label htmlFor="email">{t('users.add.Email')}</label>
				<input
					type="email"
					name="email"
					value={newUser.email}
					onChange={handleChangeUser}
					placeholder={t('users.add.Email')}
				/>
				<label htmlFor="timeZone">{t('users.add.Time-Zone')}</label>
				<Select
					showSearch
					value={newUser.timeZone}
					onChange={handleTimeZoneChange}
					placeholder={t('users.add.Time-Zone')}
				>
					{timeZones.map((tz) => (
						<Option key={tz} value={tz}>
							{tz}
						</Option>
					))}
				</Select>
				<span className="add-user-active">
					<label>{t('users.add.Activate')}</label>
					<input
						type="checkbox"
						name="active"
						checked={newUser.active}
						onChange={(e) =>
							setNewUser((prevUser) => ({
								...prevUser,
								active: e.target.checked,
							}))
						}
					/>
				</span>

				<div className="user-button">
					<button type="button" onClick={saveNewUser} className="save-button">
						{t('users.add.Activate')}
					</button>
					<button
						type="button"
						onClick={() => onClose()}
						className="save-button"
					>
						{t('users.add.Cancel')}
					</button>
				</div>
			</form>
		</div>
	);
}
