import React from 'react';
import { Space, DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export const ReceivedDateFilterForecast = ({
	setStartDateReceived,
	startDateReceived,
	setEndDateReceived,
	endDateReceived,
}) => {
	const dates = [];
	if (startDateReceived) {
		dates[0] = moment(startDateReceived);
	}

	if (endDateReceived) {
		dates[1] = moment(endDateReceived);
	}

	function handleChange(datePicker) {
		const newStartDateReceived = moment(datePicker?.[0]?.$d).format(
			'YYYY-MM-DD'
		);
		const newEndDateReceived = moment(datePicker?.[1].$d).format('YYYY-MM-DD');
		setStartDateReceived(newStartDateReceived);
		setEndDateReceived(newEndDateReceived);
	}

	return (
		<Space
			direction="vertical"
			style={{ width: '100%', margin: '10px 0' }}
			size="large"
		>
			<RangePicker onChange={handleChange} defaultValue={dates} />

			{/* <Input
				placeholder="Search by reference"
				allowClear
				// onChange={(e) => setStates(e.target.value)}
				// value={states}
			/> */}
		</Space>
	);
};
