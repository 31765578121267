import React from 'react';
import box from './img/box.png';
import './main.css';

export function ProgressBar({ timeline }) {
	const renderedUsers = [];

	for (let status of timeline?.timelineStatus) {
		renderedUsers.push(
			<div className="process-bar-dot-content">
				<div className="process-bar-dot">
					<div className="process-point"></div>
					<span className="process-bar-pointname-normal">{status.status}</span>
				</div>
			</div>
		);
	}

	return (
		<div className="progress">
			<div className="progress__container">
				<div className="progress__bar">
					<div
						className="progress-bar-inner"
						style={{
							width: `${Math.min(timeline.progress, 100)}%`,
							maxWidth: '100%',
							overflow: 'hidden',
						}}
					>
						<div className="process-bar-dot-wrap">{renderedUsers}</div>
						<div
							className="process-package-img-content"
							style={{
								left: `${Math.min(timeline.progress, 100)}%`,
								top: '27%',
								overflow: 'hidden',
							}}
						>
							<div className="process-package-icon-normal"></div>
							<img src={box} alt="Car" className="process-package-img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
