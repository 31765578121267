import React, { useEffect, useState } from 'react';
import {
	API_URL_EMPLOYEES,
	API_URL_FLEETS_SELECT,
	get,
	post,
	patch,
} from '../../App/Api';
import { Table, Spin, Alert, Button, message, Form, Space, Select } from 'antd';
import './main.css';
import { EditEmployee } from './Edit';
import { timeZones } from '../Business/TimeZone';
import { AccordeonFilters } from './Accordeon/AccordeonFilters';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../Paginator';

const { Option } = Select;

export function Employees() {
	const { t, i18n } = useTranslation();

	const [employees, setEmployees] = useState([]);
	const [employeeEdit, setEmployeeEdit] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [newEmployee, setNewEmployee] = useState({
		nickname: '',
		password: '',
		email: '',
		timeZone: '',
		active: '',
		name: '',
		fleetId: 0,
	});
	const [editingEmployeeData, setEditingEmployeeData] = useState(null);
	const [perPage, setPerPage] = useState(10);
	const [employeePage, setEmployeePage] = useState(1);
	const [searchName, setSearchName] = useState('');
	const [employeeIds, setEmployeeIds] = useState([]);
	const [fleetIds, setFleetIds] = useState([]);
	const [fleet, setFleet] = useState({});
	const [hasMorePages, setHasMorePages] = useState(true);

	const [form] = Form.useForm();

	useEffect(() => {
		fetchData();
	}, [perPage, employeePage, i18n.language]);

	useEffect(() => {
		fetchFleets();
	}, [i18n.language]);

	const fetchFleets = async () => {
		try {
			const response = await get(API_URL_FLEETS_SELECT);

			const idMap = {};
			response.data.forEach((fle) => {
				idMap[fle.name] = fle.fleetId;
			});

			setFleet(idMap);
		} catch (error) {
			console.error('Error fetching fleets:', error);
		}
	};

	const fetchData = async () => {
		setLoading(true);
		try {
			let url = `${API_URL_EMPLOYEES}?perPage=${perPage}&page=${employeePage}`;

			if (employeeIds.length) {
				url += `&employeeIds=${employeeIds.join(',')}`;
			}

			if (fleetIds.length) {
				url += `&fleetIds=${fleetIds.join(',')}`;
			}

			const response = await get(url);
			setEmployees(response.data);

			if (response.data?.length < perPage) {
				setHasMorePages(false);
			} else {
				setHasMorePages(true);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleTimeZoneChange = (value) => {
		setNewEmployee((prevUser) => ({
			...prevUser,
			timeZone: value,
		}));
	};

	const handleFleetsIdChange = (name) => {
		const fleetId = fleet[name];
		setNewEmployee((prevEmployee) => ({
			...prevEmployee,
			fleetId,
		}));
	};

	const handleChangeEmployee = (event) => {
		const { name, value } = event.target;
		const newValue =
			name === 'userId' || name === 'fleetId' ? parseInt(value, 10) : value;
		setNewEmployee((prevEmployee) => ({
			...prevEmployee,
			[name]: newValue,
		}));
	};

	const handleFormSubmit = async () => {
		try {
			await post(API_URL_EMPLOYEES, newEmployee);
			message.success(t('employees.add'));
			fetchData();
			setIsAdding(false);
			setNewEmployee({
				nickname: '',
				password: '',
				email: '',
				timeZone: '',
				active: '',
				name: '',
				fleetId: 0,
			});
			form.resetFields();
		} catch (error) {
			const text = error?.response?.data?.errors?.map((error) => error + '\n');

			message.error(text);
		}
	};

	const handleEditFormSubmit = async (values) => {
		try {
			await patch(`${API_URL_EMPLOYEES}${employees.employeeId}`, values);
			fetchData();
			setIsEditing(false);
		} catch (error) {
			console.error('Error al actualizar el empleado:', error);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const numericValue = isNaN(value) ? value : parseInt(value, 10);
		setEmployeeEdit((prevEmployee) => ({
			...prevEmployee,
			[name]: numericValue,
		}));
	};

	const handleAddEmployee = () => {
		setIsAdding(true);
		setIsEditing(false);
		setEditingEmployeeData(null);
		// form.resetFields();
	};

	const handleEditEmployee = (record, employee) => {
		setEditingEmployeeData(record);
		setIsEditing(true);
		setIsAdding(false);
		setEmployeeEdit(employee);
		form.resetFields();
	};

	const handleEdit = (employee) => {
		setIsEditing(true);
		setEmployees(employee);
	};

	const closeEditForm = () => {
		setIsEditing(null);
		fetchData();
	};

	const handleCancel = () => {
		setIsAdding(false);
		setIsEditing(false);
		setEditingEmployeeData(null);
		form.resetFields();
	};

	const loadMoreEmployee = () => {
		if (hasMorePages && !loading) {
			setLoading(true);
			setEmployeePage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (employeePage > 1 && !loading) {
			setLoading(true);
			setEmployeePage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setEmployeePage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const columns = [
		{
			title: t('employees.employeeId'),
			dataIndex: 'employeeId',
			key: 'employeeId',
		},
		{
			title: t('employees.name'),
			dataIndex: 'name',
			key: 'name',
		},
		// {
		// 	title: 'Active Status',
		// 	dataIndex: 'active',
		// 	key: 'active',
		// 	render: (text, record) => (
		// 		<div>
		// 			{record.active ? (
		// 				<Button type="danger" style={{ backgroundColor: '#142112', color: '#FFFFFF' }}>
		// 					Deactivate
		// 				</Button>
		// 			) : (
		// 				<Button type="primary" style={{ backgroundColor: '#142112', color: '#FFFFFF' }}>
		// 					Activate
		// 				</Button>
		// 			)}
		// 		</div>
		// 	),
		// },
		{
			title: t('actions'),
			key: 'edit',
			render: (employee, record) => (
				<Space>
					<Button onClick={() => handleEdit(record, employee)}>
						{t('edit')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		// FILTER OF FLEETSID
		setFleetIds: setFleetIds,

		// FILTER OF EMPLOYEEID
		setEmployeeIds: setEmployeeIds,

		// BOTTON  ADDEMPLOYEE
		handleAddEmployee: handleAddEmployee,

		handleSearch: handleSearch,
	};

	return (
		<div className="employee-chart">
			{!isAdding && !isEditing && (
				<div className="filters">
					<form onKeyDown={handleKeyPress}>
						<AccordeonFilters filterProps={filterProps} />
					</form>
				</div>
			)}
			{error && (
				<Alert message="Error" description={error} type="error" showIcon />
			)}

			{!isAdding && !isEditing && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button
									type="primary"
									onClick={handleAddEmployee}
									style={{ backgroundColor: '#142112', color: '#FFFFFF' }}
								>
									{t('employees.add')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={employeePage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreEmployee}
									disabled={loading}
									className="load-more-button"
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
							<div className="employee-table">
								<Table
									dataSource={employees}
									columns={columns}
									rowKey="employeeId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{isAdding && (
				<div className="add-employee-container">
					<h2>{t('employees.add')}</h2>
					<div className="add-employee">
						<label htmlFor="nickname">{t('employee.nickname')}:</label>
						<input
							type="text"
							name="nickname"
							value={newEmployee.nickname}
							onChange={handleChangeEmployee}
							placeholder={t('employee.nickname')}
						/>
						<label htmlFor="password">{t('employee.password')}:</label>
						<input
							type="password"
							name="password"
							value={newEmployee.password}
							onChange={handleChangeEmployee}
							placeholder={t('employee.password')}
						/>
						<label htmlFor="password">{t('employee.email')}:</label>
						<input
							type="email"
							name="email"
							value={newEmployee.email}
							onChange={handleChangeEmployee}
							placeholder={t('employee.email')}
						/>
						<label htmlFor="timeZone">{t('employee.timeZone')}:</label>
						<Select
							showSearch
							value={newEmployee.timeZone}
							onChange={handleTimeZoneChange}
							placeholder={t('employee.timeZone')}
						>
							{timeZones.map((tz) => (
								<Option key={tz} value={tz}>
									{tz.name}
								</Option>
							))}
						</Select>
						<label>{t('employee.active')}</label>
						<input
							type="checkbox"
							name="active"
							checked={newEmployee.active}
							onChange={(e) =>
								setNewEmployee((prevEmployee) => ({
									...prevEmployee,
									active: e.target.checked,
								}))
							}
						/>
						<label htmlFor="name">{t('employees.name')}:</label>
						<input
							type="text"
							name="name"
							value={newEmployee.name}
							onChange={handleChangeEmployee}
							placeholder={t('employees.name')}
						/>
						<label htmlFor="fleetId">{t('employee.employeeFleetsId')}</label>
						<Select
							showSearch
							onChange={handleFleetsIdChange}
							placeholder={t('employee.employeeFleetsId')}
						>
							{Object.keys(fleet).map((name) => (
								<Option key={name} value={name}>
									{name}
								</Option>
							))}
						</Select>

						<div className="employee-button">
							<button onClick={handleFormSubmit}>{t('employee.save')}</button>
							<button onClick={handleCancel}>{t('employee.cancel')}</button>
						</div>
					</div>
				</div>
			)}

			{isEditing && (
				<EditEmployee employeeId={employees} onClose={closeEditForm} />
			)}
		</div>
	);
}
