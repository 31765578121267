// localStorage

export const saveToLocalStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
	const item = localStorage.getItem(key);
	return item ? JSON.parse(item) : null;
};

export const removeFromLocalStorage = (key) => {
	localStorage.removeItem(key);
};

// HELPERS

export const saveTokenToLocalStorage = (token, expirationTime, roleId) => {
	localStorage.setItem('accessToken', token);
	localStorage.setItem('roleId', roleId);

	const expirationTimeInt = Number.parseInt(expirationTime, 10);
	localStorage.setItem('tokenExpiration', expirationTimeInt);
};

export const getTokenExpirationFromLocalStorage = () => {
	localStorage.getItem('accessToken');
	const expirationTime = localStorage.getItem('tokenExpiration');

	if (expirationTime) {
		const expirationTimeInt = parseInt(expirationTime);
		return expirationTimeInt;
	} else {
		return -1;
	}
};

// REFERENCE

export const historySaveToLocalStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key) => {
	const data = localStorage.getItem(key);
	return data ? JSON.parse(data) : null;
};
