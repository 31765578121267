import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Select, Spin, Checkbox, Flex } from 'antd';
import {
	API_URL_ORDERS,
	API_URL_ORDER_ID,
	API_URL_PODS_PDF,
	get,
} from '../../App/Api';
import { Paginator } from './Paginator';
import { OrderDetails } from './ModalDetails';
import { PerPagefilter } from './PerPageFilter';
import { AccordionFilters } from './Accordion/AccordionFilters';
import './main.css';
import { useTranslation } from 'react-i18next';
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	saveToLocalStorage,
} from '../Login/storage';
import { FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

export function Orders() {
	const { t, i18n } = useTranslation();

	const [checkedOrders, setCheckedOrders] = useState(
		getFromLocalStorage('order.pdfs') || []
	);

	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [page, setPage] = useState(
		parseInt(getFromLocalStorage('currentPage'), 10) || 1
	);

	// STATE FOR PETITION
	const [searchValue, setSearchValue] = useState(null);
	const [searchInput, setSearchInput] = useState(
		getFromLocalStorage('order.searchInput')
	);
	const [businessIds, setBusinessIds] = useState(
		getFromLocalStorage('order.businessIds')
	);
	const [clientIds, setClientIds] = useState(
		getFromLocalStorage('order.clientIds')
	);
	const [employeeIds, setEmployeeIds] = useState(
		getFromLocalStorage('order.employeeIds')
	);
	const [fleetIds, setFleetIds] = useState(
		getFromLocalStorage('order.fleetIds')
	);
	const [selectedStatusCodes, setSelectedStatusCodes] = useState(
		getFromLocalStorage('order.selectedStatusCodes')
	);
	const [perPage, setperPage] = useState(
		getFromLocalStorage('order.perPage') || 10
	);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [ordersDate, setOrdersDate] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [details, setDetails] = useState(null);
	const [hideOrderFilters, setHideOrderFilters] = useState(
		getFromLocalStorage('order.hideOrderFilters')
	);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const isFetched = useRef(false);

	useEffect(() => {
		fetchDetails();
	}, [selectedOrder, checkedOrders, i18n.language]);

	useEffect(() => {
		fetchData();
	}, [page, perPage, searchValue, i18n.language]);

	useEffect(() => {
		const storedOrder = localStorage.getItem('selectedOrder');
		if (storedOrder) {
			setSelectedOrder(JSON.parse(storedOrder));
		}

		const storedDetailsOrder = localStorage.getItem('selectedDetailsOrder');
		if (storedDetailsOrder) {
			setDetails(JSON.parse(storedDetailsOrder));
		}

		const lastUrl = localStorage.getItem('lastUrl');
		if (lastUrl) {
			window.history.replaceState(null, '', lastUrl);
		}
	}, []);

	const fetchData = async () => {
		setLoading(true);
		try {
			let url = `${API_URL_ORDERS}`;
			const response = await get(url, {
				perPage,
				page,
				references: searchValue ? searchValue : null,
				businessIds,
				clientIds,
				employeeIds,
				fleetIds,
				orderStatusCodes: selectedStatusCodes,
			});
			setOrders(response.data);
			setHasMorePages(response.data.length === perPage);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const fetchDetails = async () => {
		if (!selectedOrder) {
			return;
		}

		try {
			const response = await get(
				API_URL_ORDER_ID.replace('{{orderId}}', selectedOrder)
			);
			setDetails(response.data);
		} catch (error) {
			console.error(t('orders.Error'), error);
		}
	};

	const checkPdfs = (e) => {
		const orderId = e.target?.orderId;
		const checked = e.target?.checked;
		const position = checkedOrders.indexOf(orderId);

		let newCheckedOrders = [...checkedOrders];
		if (checked && position === -1) {
			newCheckedOrders.push(orderId);
		} else if (!checked && position >= 0) {
			newCheckedOrders.splice(position, 1);
		}
		e.target.checked = checked;
		saveToLocalStorage('order.pdfs', newCheckedOrders);
		setCheckedOrders(newCheckedOrders);
	};

	const handleSearch = () => {
		setPage(1);
		setSearchValue(searchInput);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const loadMoreOrders = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage((prevPage) => prevPage + 1);
		}
	};

	const handleGoToStart = () => {
		setPage(1);
	};

	const updateUrl = (newUrl) => {
		window.history.replaceState(null, '', newUrl);
		localStorage.setItem('lastUrl', newUrl);
	};

	const openDetails = (order) => {
		setSelectedOrder(order.orderId);
		setHideOrderFilters(true);
		saveToLocalStorage('order.hideOrderFilters', true);
		saveToLocalStorage('selectedOrder', order.orderId);
		saveToLocalStorage('selectedDetailsOrder', order.orderId);
		const newUrl = `#/orders/${order.orderId}`;
		updateUrl(newUrl);
	};

	const closeDetails = () => {
		setSelectedOrder(null);
		setDetails(null);
		removeFromLocalStorage('order.hideOrderFilters');
		setHideOrderFilters(false);
		removeFromLocalStorage('selectedOrder');
		const newUrl = `#/#orders`;
		updateUrl(newUrl);
	};

	const cleanOrderPdfs = () => {
		saveToLocalStorage('order.pdfs', []);
		setCheckedOrders([]);
	};

	const savePageToLocalStorage = () => {
		saveToLocalStorage('currentPage', page.toString());
	};

	useEffect(() => {
		savePageToLocalStorage(page);
	}, [page]);

	const [columns, setColumns] = useState([]);

	useEffect(() => {
		setColumns([
			{
				title: t('orders.pdf'),
				key: 'pdfs',
				render: (order, record) => (
					<>
						{order.orderStatusParentCode === 'DLV' && (
							<Checkbox
								value={order.orderId}
								orderId={order.orderId}
								checked={!!checkedOrders?.includes(order.orderId)}
								onChange={checkPdfs}
							/>
						)}
					</>
				),
			},
			{
				title: t('orders.Reference'),
				dataIndex: 'reference',
				key: 'reference',
			},
			{
				title: t('fleets.name'),
				dataIndex: 'fleetName',
				key: 'fleetName',
			},
			{
				title: t('employees.name'),
				dataIndex: 'employeeName',
				key: 'employeeName',
			},
			{
				title: t('orders.status'),
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (text, record) => (
					<>
						{record?.orderStatusParentCode === record?.orderStatusCode ? (
							<>{t('orderStatus.' + record?.orderStatusParentCode + '.name')}</>
						) : (
							<>
								{t('orderStatus.' + record?.orderStatusParentCode + '.name')} -{' '}
								{t('orderStatus.' + record?.orderStatusCode + '.name')}
							</>
						)}
					</>
				),
			},
			{
				title: t('orders.currentAddress'),
				dataIndex: 'currentAddress',
				key: 'currentAddress',
			},
			{
				title: t('orders.postalCode'),
				dataIndex: 'postalCode',
				key: 'postalCode',
			},
			{
				title: t('orders.city'),
				dataIndex: 'cityName',
				key: 'cityName',
			},
			{
				title: t('orders.Actions'),
				key: 'actions',
				render: (order, record) => (
					<>
						<Button onClick={(event) => openDetails(order, event)}>
							{t('orders.Ver Detalles')}
						</Button>
					</>
				),
			},
		]);
	}, [checkedOrders]);

	const handleInputOrderSearch = (value) => {
		setSearchInput(value);
		saveToLocalStorage('order.searchInput', value);
	};

	const handleBussinessIds = (value) => {
		setBusinessIds(value);
		saveToLocalStorage('order.businessIds', value);
	};

	const handleClientIds = (value) => {
		setClientIds(value);
		saveToLocalStorage('order.clientIds', value);
	};

	const handleEmployeeIds = (value) => {
		setEmployeeIds(value);
		saveToLocalStorage('order.employeeIds', value);
	};

	const handleSelectedStatusCodes = (value) => {
		setSelectedStatusCodes(value);
		saveToLocalStorage('order.selectedStatusCodes', value);
	};

	const filterProps = {
		handleSearch,

		handleInputOrderSearch,
		searchInput,

		handleBussinessIds,
		businessIds,

		handleClientIds,
		clientIds,

		handleEmployeeIds,
		employeeIds,

		handleSelectedStatusCodes,
		selectedStatusCodes,
	};

	const openPdfs = async () => {
		setLoading(true);
		const response = await get(
			API_URL_PODS_PDF,
			{ orderIds: checkedOrders.join(',') },
			{
				responseType: 'blob',
			}
		);
		setLoading(false);
		if (
			response.data &&
			response.headers?.['content-type'] === 'application/pdf'
		) {
			if (response?.data) {
				const file = new Blob([response.data], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL, '_blank');
			}
		}
	};

	return (
		<div className="orders-container">
			{!hideOrderFilters && (
				<div className="orders-filters">
					<form onKeyDown={handleKeyPress}>
						<AccordionFilters filterProps={filterProps} />
					</form>
				</div>
			)}
			{loading && <Spin size="large" />}
			{error && (
				<p>
					{t('orders.Error')}: {error}
				</p>
			)}
			{!loading && !error && (
				<>
					{selectedOrder ? (
						<OrderDetails
							details={details}
							closeDetails={closeDetails}
							selectedOrder={selectedOrder}
						/>
					) : (
						<>
							<div className="orders-footer">
								<Button onClick={handleGoToStart}>
									{t('orders.Ir al inicio')}
								</Button>
								{/* <Button onClick={loadMoreOrders} disabled={!hasMorePages || isLoadingMore}>Next Page</Button>                                  */}
								<Paginator
									loadMoreOrders={loadMoreOrders}
									disabled={!hasMorePages || isLoadingMore}
									loading={loading}
								/>
								<PerPagefilter perPage={perPage} setperPage={setperPage} />
							</div>
							{checkedOrders?.length > 0 && (
								<Flex gap="small" align="center" wrap>
									<Button
										className="btn-primary"
										icon={<FilePdfOutlined />}
										onClick={() => openPdfs()}
									></Button>

									<Button
										className="btn-primary"
										icon={<DeleteOutlined />}
										onClick={() => cleanOrderPdfs()}
									></Button>
								</Flex>
							)}
							<div className="orders-table">
								<Table
									dataSource={orders}
									columns={columns}
									pagination={false}
								/>
							</div>
							<div className="orders-footer">
								<Button onClick={handleGoToStart}>
									{t('orders.Ir al inicio')}
								</Button>
								{/* <Button onClick={loadMoreOrders} disabled={!hasMorePages || isLoadingMore}>Next Page</Button>                                  */}
								<Paginator
									loadMoreOrders={loadMoreOrders}
									disabled={!hasMorePages || isLoadingMore}
									loading={loading}
								/>
								<PerPagefilter perPage={perPage} setperPage={setperPage} />
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
