import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchData } from "../App/Api";
import "./main.css";

export function Input() {
  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState([""]);
  const [validacionMensaje, setValidacionMensaje] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [previousReferences, setPreviousReferences] = useState([]);

  useEffect(() => {
    const lastIndex = inputRefs.current.length - 1;
    if (inputRefs.current[lastIndex]) {
      inputRefs.current[lastIndex].focus();
    }
  }, [inputValue]);

  useEffect(() => {
    const storedReferences =
      JSON.parse(localStorage.getItem("previousReferences")) || [];
    setPreviousReferences(storedReferences);
  }, []);

  const handleChange = (event, index) => {
    const { value } = event.target;
    const list = [...inputValue];
    list[index] = value;
    setInputValue(list);
    setValidacionMensaje("");
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue[index].trim() !== "") {
        setInputValue([...inputValue, ""]);
      }
    }
  };

  const handleRemoveField = (index) => {
    const newInputValues = [
      ...inputValue.slice(0, index),
      ...inputValue.slice(index + 1),
    ];
    setInputValue(newInputValues);
    inputRefs.current.splice(index, 1);
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    if (inputValue.every((val) => val.trim() === "")) {
      setIsExpanded(false);
    }
  };

  const handleTrackClick = async () => {
    if (!inputValue) {
      setValidacionMensaje(t("input.validationMessages.emptyInput"));
      return;
    }

    const references = inputValue
      .filter((value) => value.trim() !== "")
      .map((ref) => ref.trim());

    if (references.length === 0) {
      setValidacionMensaje(t("input.validationMessages.emptyInput"));
      return;
    }

    const duplicates = references.filter(
      (item, index) => references.indexOf(item) !== index
    );
    if (duplicates.length > 0) {
      setValidacionMensaje(
        t("input.validationMessages.duplicateReference", {
          reference: duplicates[0],
        })
      );
      return;
    }

    try {
      const responses = await Promise.all(
        references.map(async (ref) => {
          try {
            const response = await fetchData(ref);
            return { reference: ref, order: response };
          } catch (error) {
            console.error(`Error fetching data for ${ref}:`, error);
            return { reference: ref, order: null };
          }
        })
      );

      const orders = responses.map((res) => ({
        reference: res.reference,
        order: res.order,
      }));
      const validResponses = orders.filter((res) => res.order !== null);
      const invalidResponses = orders.filter((res) => res.order === null);

      if (validResponses.length === references.length) {
        const queryString = orders.map((res) => res.reference).join("&");
        navigate(`/tracking/${queryString}`, {
          state: {
            orders: validResponses,
            initialReference: validResponses[0].reference,
          },
        });
      } else {
        const invalidReferences = invalidResponses
          .map((res) => res.reference)
          .join(", ");
        setValidacionMensaje(
          t("input.validationMessages.invalidReferences", {
            invalidReferences: invalidReferences,
          })
        );
      }

      setPreviousReferences(references);
      localStorage.setItem("previousReferences", JSON.stringify(references));
    } catch (error) {
      console.error("Error fetching data:", error);
      setValidacionMensaje(t("input.validationMessages.errorFetchingData"));
    }
  };

  const handlePreviousReferenceClick = async (references) => {
    try {
      const responses = await Promise.all(
        references.map(async (reference) => {
          try {
            const response = await fetchData(reference);
            return { reference, order: response };
          } catch (error) {
            console.error(`Error fetching data for ${reference}:`, error);
            return { reference, order: null };
          }
        })
      );

      const orders = responses.map((res) => ({
        reference: res.reference,
        order: res.order,
      }));
      const validResponses = orders.filter((res) => res.order !== null);
      const invalidResponses = orders.filter((res) => res.order === null);

      if (validResponses.length === references.length) {
        const queryString = validResponses
          .map((res) => res.reference)
          .join("&");
        navigate(`/tracking/${queryString}`, {
          state: {
            orders: validResponses,
            initialReference: validResponses[0].reference,
          },
        });
      } else {
        const invalidReferences = invalidResponses
          .map((res) => res.reference)
          .join(", ");
        setValidacionMensaje(
          t("input.validationMessages.invalidReferences", {
            invalidReferences: invalidReferences,
          })
        );
      }
    } catch (error) {
      console.error(`Error fetching data for ${references}:`, error);
      setValidacionMensaje(t("input.validationMessages.errorFetchingData"));
    }
  };

  const referenceCount = inputValue.filter(
    (value) => value.trim() !== ""
  ).length;

  const handleClearAll = () => {
    setInputValue([""]);
    inputRefs.current = [];
  };

  return (
    <>
      <section className="section__input">
        <button onClick={handleTrackClick} className="btn">
          {t("input.track")}
        </button>
        {!isExpanded ? (
          <input
            type="text"
            onFocus={handleFocus}
            className="initial-input"
            placeholder={t("input.enterTrackingNumber")}
          />
        ) : (
          <div className="search-content">
            <div className="search-list">
              {inputValue.map((input, index) => (
                <div key={index} className="input-group">
                  <span className="input-number">{index + 1}</span>
                  <input
                    type="text"
                    className="input"
                    value={input}
                    ref={(el) => (inputRefs.current[index] = el)}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyPress(e, index)}
                    onBlur={handleBlur}
                    placeholder={
                      index === 0 && inputValue.length === 1
                        ? t("input.separatedTrackingNumbersUsingEnter")
                        : ""
                    }
                  />
                  {inputValue.length > 1 && (
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => handleRemoveField(index)}
                    >
                      &times;
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="search-footer">
              <button onClick={handleClearAll} className="clear-btn">
                {t("input.clearAll")}
              </button>
              {validacionMensaje && (
                <p className="validacion">{validacionMensaje}</p>
              )}
              <span className="references-count">{referenceCount}/100</span>
            </div>
          </div>
        )}
      </section>
      {previousReferences.length > 0 && (
        <div className="search-previous">
          <button
            onClick={() => handlePreviousReferenceClick(previousReferences)}
            className="previous-reference-btn"
          >
            {t("input.lastTrackingNumbers")}: {previousReferences.join(", ")}
          </button>
        </div>
      )}
    </>
  );
}
