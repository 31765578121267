import { Alert, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useEffect, useState } from 'react';
import { API_URL_BUSINESS_SELECT, get } from '../../../App/Api';
import { useTranslation } from 'react-i18next';

export function BusinessFilter({ handleBussinessIds, businessIds }) {
	const { t, i18n } = useTranslation();

	const [businesses, setBusinesses] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchBusinesses();
	}, [i18n.language]);

	const fetchBusinesses = async () => {
		try {
			const response = await get(API_URL_BUSINESS_SELECT);
			setBusinesses(response.data);
			setError(null);
		} catch (error) {
			setError(
				error?.response?.data?.message
					? error.response.data.message
					: t('bussinesses.error')
			);
		}
	};

	return (
		<>
			{error && <Alert message={error} type="error" />}

			<Select
				mode="multiple"
				onChange={handleBussinessIds}
				defaultValue={businessIds}
				style={{ width: '100%', margin: '10px 0' }}
				placeholder={t('orders.businessFilter.Select businesses')}
			>
				{businesses.map((business) => (
					<Option key={business.businessId} value={business.businessId}>
						{business.name}
					</Option>
				))}
			</Select>
		</>
	);
}
