import React from "react";
import { Input } from "../Input";
import { useTranslation } from "react-i18next";
import "./main.css";

export function Busqueda() {
  const { t } = useTranslation();

  return (
    <>
      <section className="busqueda">
        <div className="busqueda_text">
          <h1>{t("busqueda.MAEGMANT")}</h1>
          <p>{t("busqueda.Solutions of Transport")}</p>
        </div>
        <div className="busqueda_input">
          <Input />
        </div>
      </section>

      {/* <section class="wave__section">
				<div class="wave wave1"></div>
				<div class="wave wave2"></div>
				<div class="wave wave3"></div>
				<div class="wave wave4"></div>
			</section> */}

      <div className="animation-area">
        <ul className="box-area">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}
