import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './main.css';
import { saveToLocalStorage } from '../Dashboard/Login/storage';

export function LenguajeService() {
	const { t, i18n } = useTranslation();

	const changeLanguage = (idioma) => {
		i18n.changeLanguage(idioma);
		saveToLocalStorage('i18n', idioma);
	};

	const variants = {
		visible: {
			transform: 'translateX(100%)',
		},

		hidden: {
			transform: 'translateX(0%)',
		},
	};

	return (
		<motion.aside
			className="aside"
			initial="visible"
			animate="hidden"
			variants={variants}
		>
			<div className="aside__lenguaje">
				<p className="aside__btn" onClick={() => changeLanguage('es')}>
					{t('navbar.español')}
				</p>
				<p className="aside__btn" onClick={() => changeLanguage('en')}>
					{t('navbar.english')}
				</p>
				<p className="aside__btn" onClick={() => changeLanguage('zh')}>
					{t('navbar.chinese')}
				</p>
			</div>
		</motion.aside>
	);
}
