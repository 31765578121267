import { I18nextProvider } from 'react-i18next';
import { Busqueda } from '../Busqueda';
import { Navbar } from '../Navbar';
import i18n from '../utils/i18n';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { BtnLogin } from '../Busqueda/BtnLogin';

function App() {
	return (
		<>
			<I18nextProvider i18n={i18n}>
				<Navbar />
				<Busqueda />
			</I18nextProvider>
		</>
	);
}

export { App };
