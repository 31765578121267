import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import afirmation from "./img/descarga.svg";
import { ProgressBar } from "../ProgressBar";
import { useLocation, useParams } from "react-router-dom";
import { DeliveryTimeline } from "../deliveryEvents";
import { useNavigate } from "react-router-dom";
import "./main.css";
import axios from "axios";
import { fetchData } from "../App/Api";
import { Input } from "../Input";
import { ModalDetails } from "../Dashboard/Orders/ModalDetails";
import { useTranslation } from "react-i18next";
import { ReferenceHistory } from "./ReferenceHistory";
import imgLogo1ro from "./img/logo1ro.png";
import imgLocation from "./img/location.svg";
import world from "../Navbar/img/world.svg";
import close from "../Navbar/img/close.svg";
import history from "./img/manage_search.svg";
import historyClose from "./img/manage_search_copy.svg";
import arrow from "./img/chevron_left.svg";
import { LenguajeAccordion } from "../navAcordion";
import { LenguajeService } from "../Lenguajes";
import { ReferenceHistoryMobile } from "./ReferenceHistoryMobile";

export function Detalles() {
  const { t, i18n } = useTranslation();
  const { reference } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedReference, setSelectedReference] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalHistory, setShowModalHistory] = useState(false);

  useEffect(() => {
    const fetchOrder = async () => {
      const refs = reference.split("&");

      try {
        if (!reference) {
          console.error("Reference is undefined");
          setLoading(false);
          return;
        }

        const responses = await Promise.all(
          refs.map(async (ref) => {
            try {
              const response = await fetchData(ref);
              return { reference: ref, order: response };
            } catch (error) {
              console.error(`Error fetching data for ${ref}:`, error);
              return { reference: ref, order: null };
            }
          })
        );

        const validResponses = responses.filter((res) => res.order !== null);
        const orders = validResponses.map((res) => ({
          reference: res.reference,
          order: res.order,
        }));

        if (orders.length > 0) {
          setOrder(orders);
          console.log(orders);
          setSelectedReference(orders[0]?.reference);
        } else {
          navigate("/");
        }
      } catch (error) {
        return false;
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [reference, i18n.language, loading, navigate]);

  const handleSelectReference = (reference) => {
    setSelectedReference(reference);
  };

  function openShowModal() {
    setShowModal(true);
  }

  function closeShowModal() {
    setShowModal(false);
  }

  function openShowModalHistory() {
    setShowModalHistory(true);
  }

  function closeShowModalHistory() {
    setShowModalHistory(false);
  }

  useEffect(() => {
    if (!loading && !order) {
      navigate("/");
    }
  }, [loading, order, navigate]);

  if (loading) {
    <p>cargando........</p>;
  }

  if (!order) {
    return null;
  }

  const selectedOrder = order.find(
    (order) => order.reference === selectedReference
  );

  return (
    <>
      <section className="detalles">
        {showModal && (
          <img
            src={close}
            className="close"
            alt="close"
            onClick={closeShowModal}
          />
        )}
        {showModal && <LenguajeService />}

        {showModalHistory && (
          <img
            src={historyClose}
            className="close"
            alt="close"
            onClick={closeShowModalHistory}
          />
        )}
        {showModalHistory && (
          <ReferenceHistoryMobile
            references={order}
            selectedReference={selectedReference}
            onSelectReference={handleSelectReference}
          />
        )}
        <div className="detalles__mobile mobile">
          <div className="detalles__texts-1">
            <img src={arrow} alt="arrow" onClick={() => navigate(-1)} />
          </div>

          <div className="detalles__texts-2">
            <img src={imgLocation} alt="" />
            <h1>Movers</h1>
          </div>

          <div className="detalles__texts-3">
            <img src={world} alt="hamburguer" onClick={openShowModal} />
            <img
              src={history}
              alt="hamburguer"
              onClick={openShowModalHistory}
            />
          </div>
        </div>
        <div className="detalles__nav detalles__nav-desktop">
          <div className="detalles__nav-container">
            <div className="detalles__texts">
              <img src={imgLocation} alt="" />
              <h1>Movers</h1>
            </div>

            <div className="detalles__navbar">
              <button onClick={() => navigate(-1)}>
                {t("details.go_back")}
              </button>              
              <LenguajeAccordion />
              <img src={imgLogo1ro} alt="logo" className="detalles__logo" />
            </div>
          </div>
        </div>
        <div className="detalles__content-container">
          {order.length > 1 && (
            <div className="detalles__content">
              <h1>{t("details.previous_tracking_references")}</h1>
              <ReferenceHistory
                references={order}
                selectedReference={selectedReference}
                onSelectReference={handleSelectReference}
              />
            </div>
          )}
          <div className="detalles__content-2">
            {selectedOrder && selectedOrder.order && (
              <div className="detalles__container">
                <span>
                  <img src={afirmation} alt="" className="detalles__img" />
                </span>

                <div className="display">
                  <span>
                    <h1 className="detalles__title">
                      {selectedOrder.order.currentStatus}
                    </h1>
                    <ul className="detalles__paquete">
                      <li>
                        <p className="detalles__code">
                          {selectedOrder.reference}
                        </p>
                        <p className="detalles__from">
                          <h6 className="detalles__subtitle detalles__subtitle--1">
                            {t("details.origin")}
                          </h6>
                          {selectedOrder.order.origin} ➡
                          <h6 className="detalles__subtitle detalles__subtitle--2">
                            {t("details.destination")}
                          </h6>
                          {selectedOrder.order.destination}
                        </p>
                      </li>
                    </ul>
                  </span>
                  <ProgressBar timeline={selectedOrder.order.timeline} />
                </div>
              </div>
            )}
            {selectedOrder && selectedOrder.order && (
              <div className="detalles__events">
                <DeliveryTimeline
                  detailStatus={selectedOrder.order.detailStatus}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
