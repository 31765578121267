import 'leaflet/dist/leaflet.css';
import './main.css';
import React, { useState } from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

export function OrderStatusMap({ detailStatus }) {
	const { t, i18n } = useTranslation();

	let position;
	for (let index = 0; index < detailStatus.length; index++) {
		const orderStatus = detailStatus[index];
		if (orderStatus.latitude && orderStatus.longitude) {
			position = [orderStatus.latitude, orderStatus.longitude];
		}
	}
	const [center, setCenter] = useState(position);

	return (
		<MapContainer
			center={center}
			zoom={13}
			style={{ height: '50vh', width: '100%' }}
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
			{detailStatus.map((orderStatus, index) => {
				if (!orderStatus.latitude || !orderStatus.longitude) {
					return <></>;
				}
				const icon = L.divIcon({
					className: 'custom-icon',
					html: `
          <div class="pin">
              <span>${orderStatus.orderStatusCode}</span>
          </div>`,
				});

				return (
					<Marker
						key={index}
						position={[orderStatus.latitude, orderStatus.longitude]}
						icon={icon}
					>
						<Popup>
							<strong>
								{orderStatus.fleetName}:{orderStatus.employeeName}
							</strong>
							<br />
							<strong>
								{t('orderStatus.' + orderStatus.orderStatusCode + '.name')}
							</strong>
							<br />
							{t('date')}: {orderStatus.dateTime}
						</Popup>
					</Marker>
				);
			})}
		</MapContainer>
	);
}
