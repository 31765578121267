import React from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';

export function PerpageForecast({ forecastPerPage, setForecastPerPage }) {
	const handlePerPageChange = (value) => {
		setForecastPerPage(value);
	};

	return (
		<Select
			onChange={handlePerPageChange}
			value={forecastPerPage}
			style={{ width: '25%', margin: '10px 0' }}
		>
			<Option value={10}>10</Option>
			<Option value={20}>20</Option>
			<Option value={50}>50</Option>
			<Option value={100}>100</Option>
		</Select>
	);
}
