import React, { useState } from 'react';
import imgWorld from './img/world.svg';
import './main.css';
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from '../Dashboard/Login/storage';

export const LenguajeAccordion = () => {
	const { t, i18n } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [arrow, setArrow] = useState(false);

	const changeLanguage = (idioma) => {
		i18n.changeLanguage(idioma);
		saveToLocalStorage('i18n', idioma);
	};

	const handleToggle = () => {
		setIsOpen(!isOpen);
		setArrow(!arrow);
	};

	return (
		<div className="menu-container">
			<div className={`lenguaje-accordion ${isOpen ? 'open' : ''}`}>
				<div className="lenguaje-header" onClick={handleToggle}>
					<img
						className={`lenguaje-icon ${arrow ? 'rotate' : ''}`}
						src={imgWorld}
						alt="arrow"
					/>
				</div>
				<div className="lenguaje-content">
					<p className="lenguaje__btn" onClick={() => changeLanguage('es')}>
						{t('navbar.español')}
					</p>
					<p className="lenguaje__btn" onClick={() => changeLanguage('en')}>
						{t('navbar.english')}
					</p>
					<p className="lenguaje__btn" onClick={() => changeLanguage('zh')}>
						{t('navbar.chinese')}
					</p>
				</div>
			</div>
    	</div>
	);
};
