import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { API_URL_CLIENTS_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function ClientFilter({ setClientIds }) {
	const { t, i18n } = useTranslation();

	const [client, setClient] = useState({});

	useEffect(() => {
		fetchClients();
	}, [i18n.language]);

	const fetchClients = async () => {
		try {
			const response = await get(API_URL_CLIENTS_SELECT);

			const idMap = {};
			response.data.forEach((emp) => {
				idMap[emp.name] = emp.clientId;
			});

			setClient(idMap);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleClientIdChange = (names) => {
		const selected = names
			.map((name) => client[name])
			.filter((id) => id !== undefined);
		setClientIds(selected);
	};

	return (
		<Select
			mode="multiple"
			onChange={handleClientIdChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.clients')}
		>
			{Object.keys(client).map((name) => (
				<Option key={name} value={name}>
					{name}
				</Option>
			))}
		</Select>
	);
}
