import React from 'react'
import quick from '../img/quick_reference.svg';
import quickSelected from '../img/quick_reference_selected.svg';

export function ReferenceHistoryMobile({ references, selectedReference, onSelectReference }) {

  console.log(references)
  return (
    <div className='reference-list-mobile'>
        <h1>Previous tracking references</h1>
        {references.map(({ reference, order }) => (
          order ? (
            <div 
                key={reference}
                className={`reference-item ${selectedReference === reference ? 'selected' : ''}`}
                onClick={() => onSelectReference(reference)}
            >
                <img src={reference === selectedReference ? quickSelected : quick } alt="quick" />
                <span>{reference}</span>
                <p>{order.currentStatus}</p>
                <p>{order.origin} ➡ {order.destination}</p>
            </div>
        ) : (
            <div key={reference} className="reference-item invalid">
                {reference} - {'Referencia no encontrada'}
            </div>
        )
      ))}
    </div>
  )
}
