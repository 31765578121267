import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import {
	historySaveToLocalStorage,
	loadFromLocalStorage,
} from '../Login/storage';
import negative from '../../Assets/Sounds/negative.mp3';
import success from '../../Assets/Sounds/success.mp3';
import { Select, Table, Tag, Space, Button } from 'antd';
import './main.css';
import { useTranslation } from 'react-i18next';
import {
	API_URL_BOXES_CODE_CHANGE_ORDER_STATUS,
	API_URL_PICKING_OPTIONS,
	get,
	post,
} from '../../App/Api';

const { Option } = Select;

export function PickingBox() {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const [errorCodes, setErrorCodes] = useState([]);
	const [successCodes, setSuccessCodes] = useState([]);
	const [responseMessage, setResponseMessage] = useState(null);
	const [history, setHistory] = useState([]);
	const [count, setCount] = useState(0);
	const [playErrorSound] = useSound(negative);
	const [playSuccessSound] = useSound(success);

	// LOCALSTORAGE
	useEffect(() => {
		const savedHistory = loadFromLocalStorage('pickingHistory');
		if (savedHistory) {
			setHistory(savedHistory);
		}

		const storedErrorCodes = loadFromLocalStorage('errorCodes');
		if (Array.isArray(storedErrorCodes)) {
			setErrorCodes(storedErrorCodes);
		}

		const storedSuccessCodes = loadFromLocalStorage('successCodes');
		if (Array.isArray(storedSuccessCodes)) {
			setSuccessCodes(storedSuccessCodes);
		}

		const storedCount = localStorage.getItem('count');
		if (storedCount) {
			setCount(parseInt(storedCount));
		}
	}, []);

	const saveHistoryToLocalStorage = (newHistory) => {
		historySaveToLocalStorage('pickingHistory', newHistory);
	};

	const saveErrorCodesToLocalStorage = (newErrorCodes) => {
		historySaveToLocalStorage('errorCodes', newErrorCodes);
	};

	const saveSuccessCodesToLocalStorage = (newSuccessCodes) => {
		historySaveToLocalStorage('successCodes', newSuccessCodes);
	};

	// INCREMENTEO DEL ID
	const incrementCount = () => {
		setCount((prevCount) => prevCount + 1);
		localStorage.setItem('count', count + 1);
	};

	// RESET
	const resetCount = () => {
		setCount(0);
		localStorage.setItem('count', 0);
	};

	const optionChange = (value) => {
		setSelectedOption(value);
	};

	// REFERENCIAS
	const sendRequest = async () => {
		setCode('');

		if (!selectedOption) {
			playErrorSound();
			setErrorMessage(t('picking.errors.selectOption'));
			return;
		}

		const trimmedCode = code.trim();

		if (history.some((entry) => entry.code.includes(trimmedCode))) {
			playErrorSound();
			setErrorMessage(t('picking.errors.codeExists'));
			return;
		}

		setLoading(true);

		try {
			const url = API_URL_BOXES_CODE_CHANGE_ORDER_STATUS.replace(
				'{{code}}',
				trimmedCode
			).replace('{{orderStatusCode}}', selectedOption);

			const response = await post(url);

			const newEntries = [];
			const successes = [];

			const box = response.data;
			const newEntry = {
				code: trimmedCode,
				name:
					options.find((option) => option.orderStatusCode === selectedOption)
						?.name || '',
				qtyOrdersBox: box.qtyOrdersBox,
				number: count + 1,
			};

			successes.unshift(newEntry);
			newEntries.unshift(newEntry);

			const newHistory = newEntries.concat(history);
			setHistory(newHistory);
			saveHistoryToLocalStorage(newHistory);
			incrementCount();

			const newSuccessCodes = successes.concat(successCodes);
			setSuccessCodes(newSuccessCodes);
			saveSuccessCodesToLocalStorage(newSuccessCodes);

			setErrorMessage(null);
			setCode('');
			playSuccessSound();
		} catch (error) {
			const newEntry = {
				code: trimmedCode,
				name:
					options.find((option) => option.orderStatusCode === selectedOption)
						?.name || '',
				message: error?.response?.data?.message,
				number: count + 1,
			};

			const newEntries = [];
			const errors = [];
			newEntries.unshift(newEntry);
			errors.unshift(newEntry);

			const newHistory = newEntries.concat(history);
			setHistory(newHistory);
			saveHistoryToLocalStorage(newHistory);
			incrementCount();

			const newErrorCodes = errors.concat(errorCodes);
			setErrorCodes(newErrorCodes);
			saveErrorCodesToLocalStorage(newErrorCodes);

			setResponseMessage({ error: true });
			setErrorMessage(
				error?.response?.data?.message || t('picking.errors.requestError')
			);
		} finally {
			setLoading(false);
		}
	};

	// OPCIONES DEL SELECTED
	const fetchOptions = async () => {
		try {
			setLoading(true);
			const response = await get(API_URL_PICKING_OPTIONS);

			setOptions(response.data);
		} catch (error) {
			setErrorMessage(t('picking.errors.requestError'));
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOptions();
	}, [i18n.language]);

	// BORRAR REFERENCIAS
	const deleteAllErrors = () => {
		const newSuccessCodes = [...successCodes];

		setErrorCodes([]);
		saveErrorCodesToLocalStorage([]);

		setHistory(newSuccessCodes);
		saveHistoryToLocalStorage(newSuccessCodes);
	};

	const deleteAllSuccess = () => {
		const newErrorCodes = [...errorCodes];
		setSuccessCodes([]);
		saveSuccessCodesToLocalStorage([]);
		setHistory(newErrorCodes);
		saveHistoryToLocalStorage(newErrorCodes);
	};
	const deleteCode = (index, isError) => {
		const newSuccessCodes = [...successCodes];
		const newErrorCodes = [...errorCodes];

		if (isError) {
			newErrorCodes.splice(index, 1);
			setErrorCodes(newErrorCodes);
			saveErrorCodesToLocalStorage(newErrorCodes);
		} else {
			newSuccessCodes.splice(index, 1);
			setSuccessCodes(newSuccessCodes);
			saveSuccessCodesToLocalStorage(newSuccessCodes);
		}

		const newHistory = newSuccessCodes.concat(newErrorCodes);
		setHistory(newHistory);
		saveHistoryToLocalStorage(newHistory);
		resetCount();
	};

	// EVENTO DE LA TECLA ENTER
	const keyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendRequest();
		}
	};

	// SONIDOS
	useEffect(() => {
		if (responseMessage !== null) {
			if (responseMessage.error) {
				playErrorSound();
			} else {
				playSuccessSound();
			}
		}
	}, [responseMessage, playErrorSound, playSuccessSound]);

	const columnSuccess = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			width: 50,
			render: (text, record, index) => index + 1,
		},
		{
			title: t('columns.Code'),
			dataIndex: 'code',
			key: 'code',
			width: 10,
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
			width: 50,
		},
		{
			title: t('columns.Quantity'),
			dataIndex: 'qtyOrdersBox',
			key: 'qtyOrdersBox',
			width: 50,
		},
		{
			title: t('columns.Action'),
			key: 'action',
			width: 50,
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteCode(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	const columnError = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			width: 10,
			render: (text, record, index) => index + 1,
		},
		{
			title: t('columns.Code'),
			dataIndex: 'code',
			key: 'code',
			width: 10,
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
			width: 10,
		},
		{
			title: t('columns.Message'),
			dataIndex: 'message',
			key: 'message',
			width: 10,
		},
		{
			title: t('columns.Action'),
			key: 'action',
			width: 10,
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteCode(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	return (
		<div className="picking-container">
			<Select
				className="picking-select"
				style={{ width: '40%', margin: '10px' }}
				value={selectedOption}
				onChange={optionChange}
				onKeyDown={keyDown}
				showSearch
				filterOption={(inputValue, option) =>
					option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
				}
			>
				<Option value="">{t('picking.selectOption')}</Option>
				{options.map((option) => (
					<Option key={option.orderStatusCode} value={option.orderStatusCode}>
						{t(`${option.name}`)}
					</Option>
				))}
			</Select>
			<input
				type="text"
				className="picking-input"
				style={{ width: '40%', margin: '10px' }}
				value={code}
				onChange={(e) => setCode(e.target.value)}
				placeholder={t('input.boxCode')}
				onKeyDown={keyDown}
			/>
			{errorMessage && <p className="warning-message">{errorMessage}</p>}
			<div className="table-container">
				<div className="success-message">
					<Button
						type="primary"
						className="btn_primary"
						onClick={() => deleteAllSuccess()}
					>
						{t('columns.delete')}
					</Button>
					<Table
						className="ant-table-small"
						columns={columnSuccess}
						dataSource={successCodes}
					/>
				</div>

				<div className="error-message">
					<Button type="primary" onClick={() => deleteAllErrors()} danger>
						{t('columns.delete')}
					</Button>
					<Table
						className="ant-table-small"
						columns={columnError}
						dataSource={errorCodes}
					/>
				</div>
			</div>
		</div>
	);
}
