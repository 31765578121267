import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import {
	HashRouter as Router,
	Route,
	Routes,
	Link,
	Navigate,
} from 'react-router-dom';
import './index.css';
import { Detalles } from './Detalles/index.jsx';
import { Login } from './Dashboard/Login/index.jsx';
import React from 'react';
import { App } from './App/App.js';
import { Control } from './Dashboard/Dashboard/index.jsx';
import { Logout } from './Dashboard/Dashboard/Logout/index.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Autentication() {
	const [loggedIn, setLoggedIn] = useState(
		localStorage.getItem('loggedIn') === 'true'
	);

	const [trackingActivated, setTrackingActivated] = useState(
		localStorage.getItem('trackingActivated') === 'true'
	);

	useEffect(() => {
		const isAuthenticated = localStorage.getItem('loggedIn') === 'true';
		setLoggedIn(isAuthenticated);

		const storedTrackingActivated =
			localStorage.getItem('trackingActivated') === 'true';
		setTrackingActivated(storedTrackingActivated);
	}, []);

	const handleLogin = () => {
		setLoggedIn(true);
		localStorage.setItem('loggedIn', 'true');
	};

	const handleLogout = () => {
		setLoggedIn(false);
		localStorage.setItem('loggedIn', 'false');
	};

	const ProtectedRouteControl = ({ element }) => {
		return loggedIn ? element : <Navigate to="/login" />;
	};

	const ProtectedRouteEdit = ({ element }) => {
		return loggedIn ? element : <Navigate to="/login" />;
	};

	const ProtectedRouteOrder = ({ element }) => {
		return loggedIn ? element : <Navigate to="/login" />;
	};

	return (
		<React.StrictMode>
			<Router>
				<Link to="/"></Link>
				<Routes>
					<Route
						path="/"
						element={<ProtectedRouteControl element={<Control onLogout={handleLogout}/>} />}
					/>
					<Route path="/users/*" element={<Navigate to="/" replace />} />
					<Route path="/dashboard/*" element={<Navigate to="/" replace />} />
					<Route path="/orders/*" element={<Navigate to="/" replace />} />
					<Route path="/routes/*" element={<Navigate to="/" replace />} />
					<Route
						path="/orders/:reference"
						element={<Navigate to="/#orders" />}
					/>
					{/* <Route path="/edit/:userId" element={<Navigate to="/#users"/>} /> */}
					{/* <Route path="/add" element={<ProtectedRouteEdit element={<Add />} />} /> */}
					<Route
						path="/login"
						element={
							loggedIn ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
						}
					/>
					<Route path="*" element={<Navigate to="/login" />} />
					<Route
						path="/tracking"
						element={
							loggedIn && !trackingActivated ? <Navigate to="/" /> : <App />
						}
					/>
					<Route
						path="/tracking/:reference"
						element={
							loggedIn && !trackingActivated ? (
								<Navigate to="/" />
							) : (
								<Detalles />
							)
						}
					/>
				</Routes>
			</Router>
			{/* {loggedIn && <Logout onLogout={handleLogout}>Cerrar sesión</Logout>} */}
		</React.StrictMode>
	);
}

function Home() {
	return <h2 className="home">Home</h2>;
}

root.render(<Autentication />);
