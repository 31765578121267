import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL_CLIENTS_SELECT, get } from '../../../App/Api';
import { useTranslation } from 'react-i18next';

export function ClientsFilter({ handleClientIds, clientIds }) {
	const { t, i18n } = useTranslation();

	const [clients, setClients] = useState([]);

	useEffect(() => {
		fetchClients();
	}, [i18n.language]);

	const fetchClients = async () => {
		try {
			const response = await get(API_URL_CLIENTS_SELECT);
			setClients(response.data);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	return (
		<Select
			mode="multiple"
			onChange={handleClientIds}
			defaultValue={clientIds}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('orders.clientsFilter.Select clients')}
		>
			{clients.map((client) => (
				<Option key={client.clientId} value={client.clientId}>
					{client.name}
				</Option>
			))}
		</Select>
	);
}
