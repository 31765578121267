import axios from 'axios';
import './main.css';
import { useNavigate } from 'react-router-dom';
import { Edit } from './Edit';
import { useEffect, useState } from 'react';
import { Add } from './Add';
import { API_URL_USERS, get, patch } from '../../App/Api';
import { Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export function Users() {
	const { t, i18n } = useTranslation();

	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [editUser, setEditUser] = useState(false);
	const [showAddForm, setShowAddForm] = useState(false);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await get(API_URL_USERS);
			setUsers(response.data);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [i18n.language]);

	// EDIT

	const handleEdit = (user) => {
		setEditUser(true);
		setUsers(user);
		// navigate(`/edit/${user.userId}`);
	};

	const closeEditForm = () => {
		setEditUser(null);
		fetchData();
	};

	// ADD

	const handleAdd = () => {
		setShowAddForm(true);
		// navigate('/add');
	};

	const closeAddForm = () => {
		setShowAddForm(null);
	};

	// ACTIVATE

	const activateUser = async (userId) => {
		try {
			await patch(`${API_URL_USERS}${userId}/activate`, null);
			fetchData();
		} catch (error) {
			setError(error.message);
		}
	};

	const deactivateUser = async (userId) => {
		try {
			await patch(`${API_URL_USERS}${userId}/deactivate`, null);

			fetchData();
		} catch (error) {
			setError(error.message);
		}
	};

	const columns = [
		{
			title: t('users.ID'),
			dataIndex: 'userId',
			key: 'userId',
		},
		{
			title: t('users.Code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t('users.Nickname'),
			dataIndex: 'nickname',
			key: 'nickname',
		},
		{
			title: t('users.Email'),
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: t('users.Action'),
			key: 'action',
			render: (_, record) => (
				<>
					<Button type="link" onClick={() => handleEdit(record)}>
						{t('users.Edit')}
					</Button>
					{record.active ? (
						<Button type="link" onClick={() => deactivateUser(record.userId)}>
							{t('users.Deactivate')}
						</Button>
					) : (
						<Button type="link" onClick={() => activateUser(record.userId)}>
							{t('users.Activate')}
						</Button>
					)}
				</>
			),
		},
	];

	return (
		<div className="tracking-table-container">
			{!showAddForm && !editUser && (
				<>
					{loading ? (
						<p>{t('users.Loading')}</p>
					) : error ? (
						<p>
							{t('users.Error')}: {error}
						</p>
					) : (
						<div className="tracking-table">
							<Table columns={columns} dataSource={users} rowKey="userId" />
							<div className="position-buttons">
								<Button type="primary" onClick={handleAdd}>
									{t('users.Add')}
								</Button>
							</div>
						</div>
					)}
				</>
			)}

			{editUser && <Edit userId={users} onClose={closeEditForm} />}
			{showAddForm && (
				<Add setShowAddForm={setShowAddForm} onClose={closeAddForm} />
			)}
		</div>
	);
}
