import React from 'react';
import { Button, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FleetFilter } from '../../../../Fleet/Filter';
import { EmployeeFilter } from '../../../../Employee/Filter';
const { Panel } = Collapse;

export function AccordeonFilters({ filterProps }) {
	const { t } = useTranslation();

	return (
		<Collapse
			defaultActiveKey={[]}
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		>
			<Panel header={t('filters.filters')} key="1">
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('employees.name')}
						</p>
						<EmployeeFilter {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('fleets.name')}
						</p>
						<FleetFilter {...filterProps} />
					</Col>
				</Row>
				<Row justify="end" align="middle" style={{ marginTop: '20px' }}>
					<Col>
						<Button
							type="primary"
							onClick={filterProps.handleSearch}
							style={{ backgroundColor: '#142112', color: '#FFFFFF' }}
						>
							{t('filters.search')}
						</Button>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
}
