import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en.json';
import zhTranslation from '../locales/zh.json';
import esTranslation from '../locales/es.json';
import { getFromLocalStorage } from '../Dashboard/Login/storage';
const lng = getFromLocalStorage('i18n');
i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enTranslation,
		},
		zh: {
			translation: zhTranslation,
		},
		es: {
			translation: esTranslation,
		},
	},
	lng: lng || 'es',
	fallbackLng: ['es', 'en', 'zh'],
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
