import React from 'react';
import { Accordion } from '..';
import { InputOrderSearch } from '../../SearchReference';
import { BusinessFilter } from '../../BussinesFilter';
import { ClientsFilter } from '../../ClientsFilter';
import { EmployeeFilter } from '../../EmployeesFilter';
import { OrderStatusCodeFilter } from '../../OrderStatusCodesFilter';
import { Button, Col, Collapse, Row } from 'antd';
import Card from 'antd/es/card/Card';
import { useTranslation } from 'react-i18next';
const { Panel } = Collapse;

export function AccordionFilters({ filterProps }) {
	const { t } = useTranslation();

	return (
		<Collapse
			accordion
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		>
			<Panel header={t('orders.accordion.Toggle-Filters')} key="mainAccordion">
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Reference')}
						</p>
						<div>
							<InputOrderSearch {...filterProps} />
						</div>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Bussines')}
						</p>
						<div>
							<BusinessFilter {...filterProps} />
						</div>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Clients')}
						</p>
						<div>
							<ClientsFilter {...filterProps} />
						</div>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Employees')}
						</p>
						<div>
							<EmployeeFilter {...filterProps} />
						</div>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Orders-Status-Codes')}
						</p>
						<div>
							<OrderStatusCodeFilter {...filterProps} />
						</div>
					</Col>
				</Row>
				<Row justify="end">
					<Col>
						<Button onClick={filterProps.handleSearch}>
							{t('orders.accordion.Search')}
						</Button>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
}
