import React, { useEffect, useState } from 'react';
import { API_URL_FLEETS, patch, get } from '../../../App/Api';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';

export function FleetsEdit({ fleetsId, onClose }) {
	const { t, i18n } = useTranslation();
	const [fleets, setFleets] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [form] = Form.useForm();

	useEffect(() => {
		fetchEdit();
	}, [FleetsEdit.fleetId, i18n.language]);

	const fetchEdit = async () => {
		try {
			const response = await get(`${API_URL_FLEETS}${fleetsId.fleetId}`);

			setFleets(response.data);
		} catch (error) {
			setError(t('fleetsEdit.error'), error);
		} finally {
			setLoading(false);
		}
	};

	const handleEditFormSubmit = async () => {
		try {
			const { createAt, updateAt, consecutiveOrder, ...dataToSend } = fleets;

			await patch(`${API_URL_FLEETS}${fleetsId.fleetId}`, dataToSend);
			message.success(t('fleetsEdit.success'));
			onClose();
		} catch (error) {
			console.error(t('fleetsEdit.error'), error);
		}
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFleets((prevFleet) => ({
			...prevFleet,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	if (loading) {
		return <p>{t('fleetsEdit.loading')}</p>;
	}

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div>
			{fleets ? (
				<div className="edit-employee-container">
					<h2>{t('fleetsEdit.editTitle')}</h2>
					<form className="edit-employee">
						<label htmlFor="name">{t('fleetsEdit.name')}:</label>
						<input
							type="text"
							name="name"
							value={fleets.name || ''}
							onChange={handleChange}
						/>

						<label htmlFor="code">{t('fleetsEdit.code')}:</label>
						<input
							type="text"
							name="code"
							value={fleets.code || ''}
							onChange={handleChange}
						/>

						<label htmlFor="active">{t('fleetsEdit.active')}:</label>
						<input
							type="checkbox"
							name="active"
							checked={fleets.active || false}
							onChange={handleChange}
						/>
					</form>
					<div className="employee-button">
						<button onClick={handleEditFormSubmit}>
							{t('fleetsEdit.save')}
						</button>
						<button onClick={onClose}>{t('fleetsEdit.cancel')}</button>
					</div>
				</div>
			) : (
				<p>{t('fleetsEdit.noData')}</p>
			)}
		</div>
	);
}
