import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	API_URL_EMPLOYEES,
	API_URL_FLEETS_SELECT,
	get,
	patch,
} from '../../../App/Api';
import { timeZones } from '../../Business/TimeZone';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export function EditEmployee({ employeeId, onClose }) {
	const { t, i18n } = useTranslation();

	const [employee, setEmployee] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [fleet, setFleet] = useState({});

	useEffect(() => {
		const fetchEdit = async () => {
			try {
				const response = await get(API_URL_EMPLOYEES + employeeId.employeeId);
				console.log(response);
				setEmployee(response.data);
			} catch (error) {
				console.error('Error fetching employee data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchEdit();
	}, [employeeId, i18n.language]);

	useEffect(() => {
		fetchFleets();
	}, [i18n.language]);

	const fetchFleets = async () => {
		try {
			const response = await get(API_URL_FLEETS_SELECT);

			const idMap = {};
			response.data.forEach((fle) => {
				idMap[fle.name] = fle.fleetId;
			});

			setFleet(idMap);
		} catch (error) {
			console.error('Error fetching fleets:', error);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const newValue =
			name === 'userId' || name === 'fleetId' || name === 'businesId'
				? parseInt(value, 10)
				: value;
		setEmployee((prevEmployee) => ({
			...prevEmployee,
			[name]: newValue,
		}));
	};

	const handleTimeZoneChange = (value) => {
		setEmployee((prevUser) => ({
			...prevUser,
			timeZone: value,
		}));
	};

	const handleFleetsIdChange = (name) => {
		const fleetId = fleet[name];
		setEmployee((prevEmployee) => ({
			...prevEmployee,
			fleetId,
		}));
	};

	const handleEditFormSubmit = async () => {
		try {
			const { employeeId, createAt, updateAt, ...dataToSend } = employee;

			await patch(`${API_URL_EMPLOYEES}${employeeId}`, dataToSend);

			onClose();
		} catch (error) {
			console.error('Error al actualizar el empleado:', error);
		}
	};

	if (loading) {
		return <p>Loading employee data...</p>;
	}

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div>
			{employee ? (
				<div className="edit-employee-container">
					<h2>{t('employees.edit')}</h2>
					<form className="edit-employee">
						<label htmlFor="code">{t('employees.code')}:</label>
						<input
							type="text"
							name="code"
							value={employee.code || ''}
							onChange={handleChange}
						/>
						<label htmlFor="nickname">{t('employees.nickname')}:</label>
						<input
							type="text"
							name="nickname"
							value={employee.nickname || ''}
							onChange={handleChange}
						/>
						<label htmlFor="password">{t('employees.password')}:</label>
						<input
							type="number"
							name="password"
							value={employee.password || ''}
							onChange={handleChange}
						/>
						<label htmlFor="email">{t('employees.email')}:</label>
						<input
							type="email"
							name="email"
							value={employee.email || ''}
							onChange={handleChange}
						/>
						<label htmlFor="timeZone">{t('employees.timeZone')}:</label>
						<Select value={employee.timeZone} onChange={handleTimeZoneChange}>
							{timeZones.map((timezone) => (
								<Option key={timezone} value={timezone}>
									{timezone}
								</Option>
							))}
						</Select>
						<label htmlFor="name">{t('employeess.name')}:</label>
						<input
							type="text"
							name="name"
							value={employee.name || ''}
							onChange={handleChange}
						/>
						<label htmlFor="fleetId">{t('employees.employeeFleetsId')}</label>
						<Select
							showSearch
							onChange={handleFleetsIdChange}
							placeholder="Seleccionar flota"
							value={Object.keys(fleet).find(
								(name) => fleet[name] === employee.fleetId
							)}
						>
							{Object.keys(fleet).map((name) => (
								<Option key={name} value={name}>
									{name}
								</Option>
							))}
						</Select>
					</form>
					<div className="employee-button">
						<button onClick={handleEditFormSubmit}>
							{t('employees.save')}
						</button>
						<button onClick={onClose}>{t('employees.cancel')}</button>
					</div>
				</div>
			) : (
				<p>{t('employees.notFound')}</p>
			)}
		</div>
	);
}
