import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function Paginator({ loadMoreOrders, loading }) {
	const { t } = useTranslation();

	return (
		<div className="orders-paginator">
			<Button
				onClick={loadMoreOrders}
				disabled={loading}
				className="load-more-button"
			>
				{loading ? t('orders.Loading') : t('orders.Next')}
			</Button>
		</div>
	);
}
