import React from 'react';
import { Input, Space } from 'antd';

export const PostalCodesFilterForecast = ({ setPostalCodes, postalCodes }) => {
	return (
		<Space
			direction="vertical"
			style={{ width: '100%', margin: '10px 0' }}
			size="large"
		>
			<Input
				placeholder="Search by reference"
				allowClear
				onChange={(e) => setPostalCodes(e.target.value)}
				value={postalCodes}
			/>
		</Space>
	);
};
