import React, { useEffect, useState } from 'react';
import { API_URL_FLEETS_SELECT, get } from '../../../App/Api';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { useTranslation } from 'react-i18next';

export function FleetFiltersFleets({ setFleetIds }) {
	const { t, i18n } = useTranslation();

	const [fleet, setFleet] = useState({});

	useEffect(() => {
		fetchFleets();
	}, [i18n.language]);

	const fetchFleets = async () => {
		try {
			const response = await get(API_URL_FLEETS_SELECT);

			const idMap = {};
			response.data.forEach((fle) => {
				idMap[fle.name] = fle.fleetId;
			});

			setFleet(idMap);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleFleetsIdChange = (names) => {
		const selected = names
			.map((name) => fleet[name])
			.filter((id) => id !== undefined);
		setFleetIds(selected);
	};

	return (
		<Select
			mode="multiple"
			onChange={handleFleetsIdChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('fleetFilters.placeholder')}
		>
			{Object.keys(fleet).map((name) => (
				<Option key={name} value={name}>
					{name}
				</Option>
			))}
		</Select>
	);
}
