import React, { useEffect, useState } from 'react';
import { API_URL_USERS, get, patch } from '../../../App/Api';
import { Select } from 'antd';
import { timeZones } from '../TimeZone';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export function Edit({ userId, onClose }) {
	const { t, i18n } = useTranslation();

	const [user, setUser] = useState(null);
	// const { userId } = useParams();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await get(`${API_URL_USERS}/${userId.userId}`);
				setUser(response.data);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchData();
	}, [userId, i18n.language]);

	// EDIT

	const handleChange = (event) => {
		const { name, value } = event.target;
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleTimeZoneChange = (value) => {
		setUser((prevUser) => ({
			...prevUser,
			timeZone: value,
		}));
	};

	const saveEdit = async () => {
		try {
			if (!user) {
				console.error('No se ha cargado ningún usuario para editar.');
				return;
			}

			const { userId } = user;

			if (typeof userId !== 'number' || isNaN(userId)) {
				console.error('El ID del usuario no es un número válido:', userId);
				return;
			}

			const { roleId, createAt, updateAt, ...editedUserData } = user;

			await patch(`${API_URL_USERS}/${userId}`, editedUserData);

			setUser((prevUser) => ({
				...prevUser,
				...editedUserData,
			}));

			onClose();
		} catch (error) {
			console.error('Error al guardar los cambios:', error.response.data);
		}
	};

	return (
		<div>
			{user ? (
				<div className="edit-form-container">
					<h2>{t('users.edit.Edit-User')}</h2>
					<div className="edit-form">
						<label htmlFor="code">{t('users.edit.Code')}:</label>
						<input
							type="text"
							id="code"
							name="code"
							value={user.code}
							onChange={handleChange}
						/>
						<label htmlFor="nickname">{t('users.edit.Nickname')}:</label>
						<input
							type="text"
							id="nickname"
							name="nickname"
							value={user.nickname}
							onChange={handleChange}
						/>
						<label htmlFor="password">{t('users.edit.Password')}:</label>
						<input
							type="password"
							id="password"
							name="password"
							value={user.password}
							onChange={handleChange}
						/>
						<label htmlFor="email">{t('users.edit.Email')}:</label>
						<input
							type="email"
							id="email"
							name="email"
							value={user.email}
							onChange={handleChange}
						/>
						<label htmlFor="timeZone">{t('users.edit.Time-Zone')}:</label>
						<Select value={user.timeZone} onChange={handleTimeZoneChange}>
							{timeZones.map((timezone) => (
								<Option key={timezone} value={timezone}>
									{timezone}
								</Option>
							))}
						</Select>
						<input
							type="text"
							id="timeZone"
							name="timeZone"
							value={user.timeZone}
							onChange={handleChange}
						/>
					</div>
					<div className="user-button">
						<button onClick={saveEdit}>{t('users.edit.Save')}</button>
						<button onClick={onClose}>{t('users.edit.Cancel')}</button>
					</div>
				</div>
			) : (
				<p>{t('users.edit.Loading-user-data...')}</p>
			)}
		</div>
	);
}
