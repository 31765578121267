import React, { useEffect, useState } from 'react';
import {
	saveToLocalStorage,
	getFromLocalStorage,
	saveTokenToLocalStorage,
} from './storage';
import { useNavigate } from 'react-router-dom';
import { API_URL_LOGIN, API_URL_USERS } from '../../App/Api';
import { LenguajeAccordion } from '../../navAcordion';
import { useTranslation } from 'react-i18next';
import { LenguajeService } from '../../Lenguajes';
import arrow from './img/arrow.svg';
import world from "../../Navbar/img/world.svg";
import close from "../../Navbar/img/close.svg";
import './main.css';

export function Login({ onLogin }) {
	const { t, i18n } = useTranslation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [validacionMensaje, setValidacionMensaje] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalHistory, setShowModalHistory] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const storedRememberMe = getFromLocalStorage('rememberMe');
		const storedEmail = localStorage.getItem('rememberedEmail');
		const storedPassword = localStorage.getItem('rememberedPassword');

		if (storedRememberMe !== null) {
			setRememberMe(storedRememberMe);
		}

		if (storedEmail && storedPassword) {
			setEmail(storedEmail);
			setPassword(storedPassword);
		}
	}, []);

	const handleTrackingClick = () => {
		localStorage.setItem('trackingActivated', 'true');
		navigate('/tracking');
	};

	const rememberChange = () => {
		const newValue = !rememberMe;
		setRememberMe(newValue);
		saveToLocalStorage('rememberMe', newValue);

		if (newValue) {
			localStorage.setItem('rememberedEmail', email);
			localStorage.setItem('rememberedPassword', password);
		} else {
			localStorage.removeItem('rememberedEmail');
			localStorage.removeItem('rememberedPassword');
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		if (name === 'email') {
			setEmail(value);
		} else if (name === 'password') {
			setPassword(value);
		}

		setValidacionMensaje('');
	};

	const handleLogin = async (event) => {
		event.preventDefault();

		try {
			const response = await fetch(API_URL_LOGIN, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': i18n.language,
				},
				body: JSON.stringify({
					email,
					password,
				}),
			});

			if (response.ok) {
				const data = await response.json();
				const token = data.access_token;
				const roleId = data.roleId;
				const userId = data.userId

				const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
				const expirationTime = Date.now() + oneDayInMilliseconds;

				saveTokenToLocalStorage(token, expirationTime, roleId);

				onLogin(token);



				const userResponse = await fetch(`${API_URL_USERS}/${userId}` ,{
					headers: {
						'Authorization': `Bearer ${token}`,
						'Accept-Language': i18n.language,
					}
				})

				if (userResponse.ok) {
					const userDetail = await userResponse.json();
					navigate('/', { state: { userDetail } });
				} else {
					setValidacionMensaje(t('login.userFetchError'));
				}

				
			} else {
				setValidacionMensaje(t('login.invalidCredentials'));
			}
		} catch (error) {
			console.error('Error al iniciar sesión:', error);
			setValidacionMensaje(t('login.networkError', { error: error.message }));
		}
	};

	function openShowModal() {
		setShowModal(true);
	  }
	
	  function closeShowModal() {
		setShowModal(false);
	  }

	return (
		<section>
			<div className="lenguaje-login">
				{showModal && (
				<img
					src={close}
					className="close_aside"
					alt="close"
					onClick={closeShowModal}
				/>
				)}
				{showModal && <LenguajeService />}
				<div className='login-mobile'>
					<img src={world} alt="hamburguer" onClick={openShowModal} />
				</div>
				<div className='login-desktop'>
					<LenguajeAccordion />
				</div>
				<div className="tracking-btn" onClick={handleTrackingClick}>
				<span>
					<button className="login-tracking login-right">
						{t('login.trackingButton')}
					</button>
				</span>
				<figure>
					<img src={arrow} alt="arrow" className="arrow-login" />
				</figure>
			</div>
			</div>
			<div className="container">
				<div className="card">
					<h1 className="title">{t('login.title')}</h1>
					<form onSubmit={handleLogin}>
						<div className="input-container">
							<input
								type="text"
								id="email"
								name="email"
								value={email}
								onChange={handleChange}
								required
							/>
							<label htmlFor="email">{t('login.emailLabel')}</label>
							<div className="bar"></div>
						</div>
						<div className="input-container">
							<input
								type="password"
								id="password"
								name="password"
								value={password}
								onChange={handleChange}
								required
							/>
							<label htmlFor="password">{t('login.passwordLabel')}</label>
							<div className="bar"></div>
						</div>
						<div className="remember-me">
							<input
								type="checkbox"
								id="remember-me"
								checked={rememberMe}
								onChange={rememberChange}
							/>
							<label htmlFor="remember-me">{t('login.rememberMeLabel')}</label>
						</div>
						<div className="button-container">
							<button type="submit">{t('login.loginButton')}</button>
						</div>
						{validacionMensaje && (
							<p className="contact__validation">{validacionMensaje}</p>
						)}
					</form>
				</div>
			</div>
			
		</section>
	);
}
