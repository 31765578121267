import React, { useEffect, useState } from 'react';
import { API_URL_ROUTES, get, patch } from '../../../App/Api';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';

export function RoutesEdit({ routesId, onClose }) {
	const { t, i18n } = useTranslation();
	const [routes, setRoutes] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [form] = Form.useForm();

	useEffect(() => {
		fetchEdit();
	}, [RoutesEdit.routeId, i18n.language]);

	const fetchEdit = async () => {
		try {
			const response = await get(`${API_URL_ROUTES}${routesId.routeId}`);

			setRoutes(response.data);
		} catch (error) {
			setError(t('routesEdit.error'), error);
		} finally {
			setLoading(false);
		}
	};

	const handleEditFormSubmit = async () => {
		try {
			const { createAt, updateAt, consecutiveOrder, ...dataToSend } = routes;

			await patch(`${API_URL_ROUTES}${routesId.routeId}`, dataToSend);
			message.success(t('routesEdit.success'));
			onClose();
		} catch (error) {
			console.error(t('routesEdit.error'), error);
		}
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setRoutes((prevRoute) => ({
			...prevRoute,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	if (loading) {
		return <p>{t('routesEdit.loading')}</p>;
	}

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div>
			{routes ? (
				<div className="edit-employee-container">
					<h2>{t('routesEdit.editTitle')}</h2>
					<form className="edit-employee">
						<label htmlFor="name">{t('routesEdit.name')}:</label>
						<input
							type="text"
							name="name"
							value={routes.name || ''}
							onChange={handleChange}
						/>

						<label htmlFor="code">{t('routesEdit.code')}:</label>
						<input
							type="text"
							name="code"
							value={routes.code || ''}
							onChange={handleChange}
						/>

						<label htmlFor="active">{t('routesEdit.active')}:</label>
						<input
							type="checkbox"
							name="active"
							checked={routes.active || false}
							onChange={handleChange}
						/>
					</form>
					<div className="employee-button">
						<button onClick={handleEditFormSubmit}>
							{t('routesEdit.save')}
						</button>
						<button onClick={onClose}>{t('routesEdit.cancel')}</button>
					</div>
				</div>
			) : (
				<p>{t('routesEdit.noData')}</p>
			)}
		</div>
	);
}
