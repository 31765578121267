import React from 'react';
import { Timeline } from 'antd';
import './main.css';

export function DeliveryTimeline({ detailStatus }) {
    return (
        <Timeline className="delivery-timeline">
            {detailStatus.map((event, index) => (
                <Timeline.Item key={index} className="delivery-item">
                    <div className="delivery-event">
                        <div className="timestamp">
                            <time dateTime={event.dateTime}>
                                {event.dateTime}
                            </time>
                        </div>
                        <div className="status">
                            {event.name}
                            {event.description && (
                                <span className="carrier-note"> - {event.description}</span>
                            )}
                        </div>
                    </div>
                </Timeline.Item>
            ))}
        </Timeline>
    );
}
