import React, { useEffect, useState, useRef } from 'react';
import useSound from 'use-sound';
import negative from './sounds/negative.mp3';
import success from './sounds/success.mp3';
import { Select, Table, Tag, Space, Button } from 'antd';
import './main.css';
import { useTranslation } from 'react-i18next';
import { API_URL_INTERATION_MRW_GET_LABEL_REFERENCE, get } from '../../App/Api';

const { Option } = Select;

export function MRW() {
	const { t, i18n } = useTranslation();

	const [pdfData, setPdfData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const [reference, setReference] = useState('');
	const [responseMessage, setResponseMessage] = useState(null);
	const [playErrorSound] = useSound(negative);
	const [playSuccessSound] = useSound(success);

	const referenceInputRef = useRef(null);
	// Peticion
	const sendRequest = async () => {
		setReference('');
		setPdfData('');
		setErrorMessage('');

		referenceInputRef.current.disabled = true;

		const trimmedReference = reference.trim();

		setLoading(true);
		const url = API_URL_INTERATION_MRW_GET_LABEL_REFERENCE.replace(
			'{{reference}}',
			trimmedReference
		);

		try {
			const response = await get(
				url,
				{},
				{
					responseType: 'blob',
				}
			);

			const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

			const reader = new FileReader();

			reader.onload = () => {
				setPdfData(reader.result);
			};
			reader.readAsDataURL(pdfBlob);
			setResponseMessage({ error: false });
		} catch (error) {
			setResponseMessage({ error: true });
			setErrorMessage(t('mrw.errors.requestError'));
		} finally {
			setLoading(false);
			referenceInputRef.current.disabled = false;
			referenceInputRef.current.focus();
		}
	};

	// EVENTO DE LA TECLA ENTER
	const keyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendRequest();
		}
	};

	// SONIDOS
	useEffect(() => {
		if (responseMessage !== null) {
			if (responseMessage.error) {
				playErrorSound();
			} else {
				playSuccessSound();
			}
		}
	}, [responseMessage, playErrorSound, playSuccessSound]);

	return (
		<div className="mrw-chart">
			<div className="mrw-container">
				<input
					type="text"
					id="mrw-input-reference"
					ref={referenceInputRef}
					className="mrw-input"
					style={{ width: '40%', margin: '10px' }}
					value={reference}
					onChange={(e) => setReference(e.target.value)}
					placeholder={t('input.Enter tracking number(s) here')}
					onKeyDown={keyDown}
				/>

				{errorMessage && <p className="warning-message">{errorMessage}</p>}
			</div>
			{pdfData && (
				<iframe src={pdfData} onLoad={() => {}} width={'100%'} height={600} />
			)}
		</div>
	);
}
