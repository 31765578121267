import {
	Button,
	Card,
	Modal,
	Tabs,
	Timeline,
	Descriptions,
	Badge,
	Tag,
} from 'antd';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	API_URL_LABEL_PDF,
	API_URL_ORDER_ID_STATUS,
	API_URL_POD_PDF,
	fetchData,
	get,
} from '../../../App/Api';
import axios from 'axios';
import TabPane from 'antd/es/tabs/TabPane';
import { PDFViewerComponent } from '../PdfPod';
import {
	UserOutlined,
	MailOutlined,
	PhoneOutlined,
	CalendarOutlined,
	EnvironmentOutlined,
	FileTextOutlined,
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	MinusCircleOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { OrderStatusMap } from '../OrderStatusMap';
import { OrderStatusTracking } from '../OrderStatusTracking';

export function OrderDetails({ details, closeDetails, selectedOrder }) {
	const { t, i18n } = useTranslation();

	const [activeTab, setActiveTab] = useState('1');
	const [trackingDetails, setTrackingDetails] = useState({});
	const [loading, setLoading] = useState(true);

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	useEffect(() => {
		const fetchOrder = async () => {
			try {
				if (details.orderId) {
					const response = await get(
						API_URL_ORDER_ID_STATUS.replace('{{orderId}}', details.orderId)
					);
					setTrackingDetails(response.data);
				}
			} catch (error) {
				return false;
			} finally {
				setLoading(false);
			}
		};

		fetchOrder();
	}, [selectedOrder, i18n.language, details]);

	if (loading) {
		<p>{t('orders.orderDetails.Loading order data...')}</p>;
	}

	const colorDays = (days) => {
		if (days > 2) {
			return 'red';
		} else {
			return 'green';
		}
	};

	const address =
		details?.currentAddress +
		' ' +
		details?.postalCode +
		' ' +
		details?.cityName +
		' ' +
		details?.stateName;
	const items = [
		{
			key: '1',
			label: (
				<>
					<Tag color="default">{t('orders.status')}</Tag>
				</>
			),
			children: (
				<Tag icon={<CheckCircleOutlined />} color="success">
					{t('orderStatus.' + details?.orderStatusCode + '.name')}
				</Tag>
			),
		},
		{
			key: '2',
			label: (
				<>
					<Tag color="default">{t('orders.reference')}</Tag>
				</>
			),
			children: details?.reference,
		},
		{
			key: '3',
			label: (
				<>
					<Tag color="default">{t('clients.name')}</Tag>
				</>
			),
			children: details?.clientName,
		},
		{
			key: '4',
			label: (
				<>
					<Tag icon={<UserOutlined />} color="default">
						{t('recipients.name')}
					</Tag>
				</>
			),
			children: details?.recipientName,
		},

		{
			key: '5',
			label: (
				<>
					<Tag icon={<PhoneOutlined />} color="default">
						{t('recipients.phoneNumber')}
					</Tag>
				</>
			),
			children: details?.recipientPhoneNumber,
		},
		{
			key: '6',
			label: (
				<>
					<Tag icon={<MailOutlined />} color="default">
						{t('recipients.email')}
					</Tag>
				</>
			),
			children: details?.recipientEmail,
		},
		{
			key: '7',
			label: (
				<>
					<Tag color="default">{t('orders.currentAddress')}</Tag>
				</>
			),
			children: (
				<a
					href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
						address
					)}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{address}
				</a>
			),
		},
		{
			key: '8',
			label: (
				<>
					<Tag color="default">{t('orders.postalCode')}</Tag>
				</>
			),
			children: details?.postalCode,
		},
		{
			key: '9',
			label: (
				<>
					<Tag color="default">{t('orders.city')}</Tag>
				</>
			),
			children: details?.cityName + '-' + details?.stateName,
		},
		{
			key: '10',
			label: (
				<>
					<Tag color="default">{t('orders.employee')}</Tag>
				</>
			),
			children: (
				<>
					{details?.employeeName ? details?.employeeName : ''}
					{details?.fleetName ? details?.fleetName : ''}
				</>
			),
		},
		{
			key: '11',
			label: (
				<>
					<Tag color="default">{t('orders.receivedDate')}</Tag>
				</>
			),
			children: details?.receivedDate,
		},
		{
			key: '12',
			label: (
				<>
					<Tag color="default">{t('orders.firstAttemptDate')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color={colorDays(details?.firstAttemptDays)}>
						{details?.firstAttemptDays}
					</Tag>
					{details?.firstAttemptDate}
				</>
			),
		},
		{
			key: '13',
			label: (
				<>
					<Tag color="default">{t('orders.deliveryDate')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color={colorDays(details?.deliveryDays)}>
						{details?.deliveryDays}
					</Tag>
					{details?.deliveryDate}
				</>
			),
		},
	];

	return (
		<div className="order-details">
			{details ? (
				<Tabs
					activeKey={activeTab}
					onChange={handleTabChange}
					centered
					className="order-details-tabs"
					type="line"
					tabBarGutter={20}
				>
					<TabPane tab={t('orders.details')} key="1">
						<Badge.Ribbon
							status="processing"
							text={t('orderStatus.' + details?.orderStatusCode + '.name')}
						>
							<Card style={{ width: '100%' }}>
								<Descriptions title={t('orders.details')} items={items} />
							</Card>
						</Badge.Ribbon>
					</TabPane>
					<TabPane tab={t('orders.mapEvent')} key="2">
						<Card style={{ width: '100%' }}>
							<OrderStatusMap detailStatus={trackingDetails} />
						</Card>
					</TabPane>
					<TabPane tab={t('orders.tracking')} key="3">
						<Card style={{ width: '100%' }}>
							<OrderStatusTracking detailStatus={trackingDetails} />
						</Card>
					</TabPane>
					<TabPane tab={t('orders.shippingLabel')} key="4">
						<Card style={{ width: '100%' }}>
							<PDFViewerComponent
								pdfUrl={API_URL_LABEL_PDF.replace(
									'{{orderId}}',
									details?.orderId
								)}
							/>
						</Card>
					</TabPane>
					<TabPane tab={t('orders.pod')} key="5">
						<Card style={{ width: '100%' }}>
							{details?.orderStatusParentCode === 'DLV' ? (
								<PDFViewerComponent
									pdfUrl={API_URL_POD_PDF.replace(
										'{{orderId}}',
										details?.orderId
									)}
								/>
							) : (
								<p>{t('orders.notDelivery')}</p>
							)}
						</Card>
					</TabPane>
				</Tabs>
			) : (
				<p>{t('orders.orderDetails.Loading order data...')}</p>
			)}

			<Button className="btn-primary" onClick={closeDetails}>
				{t('orders.orderDetails.Back')}
			</Button>
		</div>
	);
}
