import React, { useEffect, useState } from 'react';
import { get } from '../../../App/Api';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';

export function PDFViewerComponent({ pdfUrl }) {
	const { t, i18n } = useTranslation();

	const accessToken = localStorage.getItem('accessToken');
	const [isLoading, setIsLoading] = useState(true);
	const [pdfData, setPdfData] = useState(null);

	async function getPdf() {
		const response = await get(
			pdfUrl,
			{},
			{
				responseType: 'blob',
			}
		);

		if (
			response.data &&
			response.headers?.['content-type'] === 'application/pdf'
		) {
			return response;
		} else {
			console.error('Unexpected response format');
			return false;
		}
	}
	useEffect(() => {
		const fetchPDF = async () => {
			const response = await getPdf();
			if (response?.data) {
				const pdfBlob = response.data;
				const reader = new FileReader();
				reader.onload = () => {
					setPdfData(reader.result);
					setIsLoading(false);
				};
				reader.readAsDataURL(pdfBlob);
			}
		};

		fetchPDF();
	}, []);

	const openPdf = async () => {
		const response = await getPdf();

		if (response?.data) {
			const file = new Blob([response.data], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL, '_blank');
		}
	};

	if (isLoading) {
		return <div>Loading PDF...</div>;
	}

	return (
		<Flex vertical gap="small" style={{ width: '100%' }}>
			<Button
				className="btn-primary"
				icon={<FilePdfOutlined />}
				onClick={() => openPdf()}
			>
				{t('openPdf')}
			</Button>
			<iframe src={pdfData} onLoad={() => {}} width={'100%'} height={600} />
		</Flex>
	);
}
