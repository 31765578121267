import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { API_URL_EMPLOYEES_SELECT, get } from '../../../App/Api';
import { useTranslation } from 'react-i18next';

export function EmployeeFilterFleets({ setEmployeeIds }) {
	const { t, i18n } = useTranslation();

	const [employee, setEmployee] = useState({});

	useEffect(() => {
		fetchEmployees();
	}, [i18n.language]);

	const fetchEmployees = async () => {
		try {
			const response = await get(API_URL_EMPLOYEES_SELECT);

			const idMap = {};
			response.data.forEach((emp) => {
				idMap[emp.name] = emp.employeeId;
			});

			setEmployee(idMap);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleEmployeeIdChange = (names) => {
		const selected = names
			.map((name) => employee[name])
			.filter((id) => id !== undefined);
		setEmployeeIds(selected);
	};

	return (
		<Select
			mode="multiple"
			onChange={handleEmployeeIdChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('employeeFilter.placeholder')}
		>
			{Object.keys(employee).map((name) => (
				<Option key={name} value={name}>
					{name}
				</Option>
			))}
		</Select>
	);
}
