import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, message, Form, Space } from 'antd';
import { API_URL_FLEETS, get, post } from '../../App/Api';
import './main.css';
import { FleetsEdit } from './FleetsEdit';
import { PaginatorFleets } from './PaginatorFleets';
import { PerpageFleets } from './PerPageFleets';
import { AccordeonFiltersFleets } from './AccordeonFleets/AccordeonFilterFleets';
import { useTranslation } from 'react-i18next';

export function Fleets() {
	const { t, i18n } = useTranslation();
	const [fleets, setFleets] = useState([]);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [fleetsPerPage, setFleetsPerPage] = useState(10);
	const [fleetsPage, setFleetsPage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [fleetIds, setFleetIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [newFleets, setNewFleets] = useState({
		name: '',
		code: '',
		active: '',
	});
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchData();
	}, [fleetsPerPage, fleetsPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = `${API_URL_FLEETS}?perPage=${fleetsPerPage}&page=${fleetsPage}`;

			if (fleetIds.length) {
				url += `&fleetIds=${fleetIds.join(',')}`;
			}

			if (employeeIds.length) {
				url += `&employeeIds=${employeeIds.join(',')}`;
			}

			const response = await get(url);

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === fleetsPerPage);
				setFleets(response.data);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const handleFleetsAdd = async () => {
		try {
			await post(API_URL_FLEETS, newFleets);
			message.success(t('fleets.addFleetSuccess'));
			fetchData();
			setIsAdding(false);
			setNewFleets({
				name: '',
				code: '',
				active: '',
			});
			form.resetFields();
		} catch (error) {
			message.error(t('fleets.addFleetError'));
		}
	};

	const handleChangeFleets = (event) => {
		const { name, value } = event.target;
		setNewFleets((prevEmployee) => ({
			...prevEmployee,
			[name]: value,
		}));
	};

	const handleAddFleets = () => {
		setIsAdding(true);
		setIsEditing(false);
		// form.resetFields();
	};

	const handleEdit = (fleet) => {
		setIsEditing(true);
		setFleets(fleet);
	};

	const closeEditForm = async () => {
		await fetchData();
		setIsEditing(null);
	};

	const handleCancel = () => {
		setIsAdding(false);
		setIsEditing(false);
		form.resetFields();
	};

	const loadMoreFleets = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setFleetsPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (fleetsPage > 1) {
			setFleetsPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setFleetsPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const columns = [
		{
			title: t('fleets.fleetId'),
			dataIndex: 'fleetId',
			key: 'fleetId',
			align: 'center',
		},
		{
			title: t('fleets.name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
		},
		{
			title: t('fleets.code'),
			dataIndex: 'code',
			key: 'code',
			align: 'center',
		},
		{
			title: t('fleets.actions'),
			key: 'edit',
			render: (employee, record) => (
				<Space>
					<Button onClick={() => handleEdit(record, employee)}>
						{t('fleets.edit')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		// FILTER OF FLEETSID
		setFleetIds: setFleetIds,

		// FILTER OF EMPLOYEEID
		setEmployeeIds: setEmployeeIds,

		// BOTTON  ADDEMPLOYEE
		handleAddFleets: handleAddFleets,

		handleSearch: handleSearch,
	};

	return (
		<div className="fleets-chart">
			{!isAdding && !isEditing && (
				<div className="filters">
					<form onKeyDown={handleKeyPress}>
						<AccordeonFiltersFleets filterProps={filterProps} />
					</form>
				</div>
			)}
			{!isAdding && !isEditing && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button
									type="primary"
									onClick={handleAddFleets}
									style={{ backgroundColor: '#142112', color: '#FFFFFF' }}
								>
									{t('fleets.addFleet')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={fleetsPage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreFleets}
									disabled={!hasMorePages || isLoadingMore}
								>
									{t('nextPage')}
								</Button>
								<PerpageFleets
									fleetsPerPage={fleetsPerPage}
									setFleetsPerPage={setFleetsPerPage}
								/>
							</div>
							<div className="fleets-table">
								<Table
									dataSource={fleets}
									columns={columns}
									rowKey="fleetId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{isAdding && (
				<div className="add-employee-container">
					<h2>{t('fleets.addFleetTitle')}</h2>
					<div className="add-employee">
						<label htmlFor="name">{t('fleets.name')}:</label>
						<input
							type="text"
							name="name"
							value={newFleets.name}
							onChange={handleChangeFleets}
							placeholder="Name"
						/>
						<label htmlFor="code">{t('fleets.code')}:</label>
						<input
							type="text"
							name="code"
							value={newFleets.code}
							onChange={handleChangeFleets}
							placeholder="Code"
						/>

						<label>{t('fleets.active')}</label>
						<input
							type="checkbox"
							name="active"
							checked={newFleets.active}
							onChange={(e) =>
								setNewFleets((prevFleet) => ({
									...prevFleet,
									active: e.target.checked,
								}))
							}
						/>

						<div className="employee-button">
							<button onClick={handleFleetsAdd}>{t('fleets.save')}</button>
							<button onClick={handleCancel}>{t('fleets.cancel')}</button>
						</div>
					</div>
				</div>
			)}

			{isEditing && <FleetsEdit fleetsId={fleets} onClose={closeEditForm} />}
		</div>
	);
}
