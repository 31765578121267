import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, message, Form } from 'antd';
import { API_URL_ROUTES, post, get } from '../../App/Api';
import './main.css';
import { RoutesEdit } from './RoutesEdit';
import { PerpageRoutes } from './PerPageRoutes';
import { AccordeonFiltersRoutes } from './AccordeonRoutes/AccordeonFilterRoutes';
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from '../Login/storage';

export function Routes() {
	const { t, i18n } = useTranslation();
	const [routes, setRoutes] = useState([]);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [routesPerPage, setRoutesPerPage] = useState(10);
	const [routesPage, setRoutesPage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [date, setDate] = useState(null);
	const [fleetIds, setFleetIds] = useState([]);
	const [searchReference, setSearchReference] = useState(null);
	const [clientIds, setClientIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [newRoutes, setNewRoutes] = useState({
		name: '',
		code: '',
		active: '',
	});
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchData();
	}, [routesPerPage, routesPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = API_URL_ROUTES;

			const response = await get(url, {
				perPage: routesPerPage,
				page: routesPage,
				date,
				fleetIds,
				references: searchReference,
				employeeIds,
				clientIds,
			});

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === routesPerPage);
				setRoutes(response.data);
				setError(null);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const handleRoutesAdd = async () => {
		try {
			await post(API_URL_ROUTES, newRoutes);
			message.success(t('routes.addRouteSuccess'));
			fetchData();
			setIsAdding(false);
			setNewRoutes({
				name: '',
				code: '',
				active: '',
			});
			form.resetFields();
		} catch (error) {
			message.error(t('routes.addRouteError'));
		}
	};

	const handleChangeRoutes = (event) => {
		const { name, value } = event.target;
		setNewRoutes((prevEmployee) => ({
			...prevEmployee,
			[name]: value,
		}));
	};

	const handleAddRoutes = () => {
		setIsAdding(true);
		setIsEditing(false);
		// form.resetFields();
	};

	const handleEdit = (route) => {
		setIsEditing(true);
		setRoutes(route);
	};

	const closeEditForm = async () => {
		await fetchData();
		setIsEditing(null);
	};

	const handleCancel = () => {
		setIsAdding(false);
		setIsEditing(false);
		form.resetFields();
	};

	const loadMoreRoutes = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setRoutesPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (routesPage > 1) {
			setRoutesPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setRoutesPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	const handleInputOrderSearch = (value) => {
		if (!value) {
			value = null;
		}
		setSearchReference(value);
		saveToLocalStorage('routes.searchReference', value);
	};

	const columns = [
		{
			title: t('fleets.name'),
			dataIndex: 'fleetName',
			key: 'fleetName',
			align: 'center',
		},
		{
			title: t('employees.name'),
			dataIndex: 'employeeName',
			key: 'employeeName',
			align: 'center',
		},
		{
			title: t('orders.qty'),
			dataIndex: 'orders',
			key: 'orders',
			align: 'center',
		},
		{
			title: t('orderStatus.RFD.name'),
			dataIndex: 'RFD',
			key: 'RFD',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('orderStatus.FLD.name'),
			dataIndex: 'FLD',
			key: 'FLD',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('orderStatus.DLV.name'),
			dataIndex: 'DLV',
			key: 'DLV',
			align: 'center',
			render: (text) => text || '0',
		},
		// {
		// 	title: t('routes.actions'),
		// 	key: 'edit',
		// 	render: (employee, record) => (
		// 		<Space>
		// 			<Button onClick={() => handleEdit(record, employee)}>
		// 				{t('routes.detail')}
		// 			</Button>
		// 		</Space>
		// 	),
		// },
	];

	const filterProps = {
		setDate,
		setFleetIds,
		setEmployeeIds,
		setClientIds,
		handleAddRoutes,
		handleSearch,
		handleInputOrderSearch,
	};

	return (
		<div className="routes-chart">
			{!isAdding && !isEditing && (
				<div className="filters">
					<form onKeyDown={handleKeyPress}>
						<AccordeonFiltersRoutes filterProps={filterProps} />
					</form>
				</div>
			)}
			{!isAdding && !isEditing && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button
									onClick={handlePreviousPage}
									disabled={routesPage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreRoutes}
									disabled={!hasMorePages || isLoadingMore}
								>
									{t('nextPage')}
								</Button>
								<PerpageRoutes
									routesPerPage={routesPerPage}
									setRoutesPerPage={setRoutesPerPage}
								/>
							</div>
							<div className="routes-table">
								<Table
									dataSource={routes}
									columns={columns}
									rowKey="fleetId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{isAdding && (
				<div className="add-employee-container">
					<h2>{t('routes.addRouteTitle')}</h2>
					<div className="add-employee">
						<label htmlFor="name">{t('routes.name')}:</label>
						<input
							type="text"
							name="name"
							value={newRoutes.name}
							onChange={handleChangeRoutes}
							placeholder="Name"
						/>
						<label htmlFor="code">{t('routes.code')}:</label>
						<input
							type="text"
							name="code"
							value={newRoutes.code}
							onChange={handleChangeRoutes}
							placeholder="Code"
						/>

						<label>{t('routes.active')}</label>
						<input
							type="checkbox"
							name="active"
							checked={newRoutes.active}
							onChange={(e) =>
								setNewRoutes((prevRoute) => ({
									...prevRoute,
									active: e.target.checked,
								}))
							}
						/>

						<div className="employee-button">
							<button onClick={handleRoutesAdd}>{t('routes.save')}</button>
							<button onClick={handleCancel}>{t('routes.cancel')}</button>
						</div>
					</div>
				</div>
			)}

			{isEditing && <RoutesEdit routesId={routes} onClose={closeEditForm} />}
		</div>
	);
}
