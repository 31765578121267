import React, { useState, useEffect } from 'react';
import { Users } from '../Business';
import { Bar } from './Barra';
import cloud from './img/cloud.svg';
import account from './img/account.svg';
import add_circle from './img/add_circle.svg';
import world from './img/world.svg';
import close from './img/close.svg';
import { Dashboard } from '../Dash';
import { TabsOrders } from '../Tab';
import { Routes } from '../Routes';
import './main.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout } from './Logout';
import { Employees } from '../Employees';
import { Fleets } from '../Fleets';
import { Forecast } from '../Forecast';

import { LenguajeAccordion } from '../../navAcordion';
import { useTranslation } from 'react-i18next';
import { Orders } from '../Orders';
import { Picking } from '../Picking';
import { LenguajeService } from '../../Lenguajes';
import { BarMobile } from './BarMobile';
import { Account } from '../Account';
import { Following } from '../Following';
import { MRW } from '../Mrw';
import { MrwMultiple } from '../MrwMultiple';
import { PickingBox } from '../PickingBox';
import { LabelMultiple } from '../LabelMultiple';

export function Control({ accessToken, onLogout }) {
	const { t } = useTranslation();
	const [pageTitle, setPageTitle] = useState('Tracking Dashboard');
	const [loggedIn, setLoggedIn] = useState(false);
	const [activeTab, setActiveTab] = useState('');
	const [userDetail, setUserDetail] = useState(null); // Estado para almacenar userDetail
	const [showModal, setShowModal] = useState(false);
	const [showAccount, setShowAccount] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const storedUserDetail = localStorage.getItem('userDetail');
		if (storedUserDetail) {
			setUserDetail(JSON.parse(storedUserDetail));
		}

		const { userDetail } = location.state || {};
		if (userDetail) {
			setUserDetail(userDetail);
			localStorage.setItem('userDetail', JSON.stringify(userDetail));
		}
	}, [location.state]);

	accessToken = localStorage.getItem('accessToken');

	function getPageTitleFromHash(hash) {
		return 'control.' + hash.replace('#', '');
	}

	const handleTabClick = (tab) => {
		const cleanTab = tab.endsWith('#') ? tab.slice(0, -1) : tab;
		if (cleanTab !== activeTab) {
			setPageTitle(getPageTitleFromHash(cleanTab));
			localStorage.setItem('activeTab', cleanTab);
			setActiveTab(cleanTab);
			navigate(cleanTab);
		}
	};

	useEffect(() => {
		if (accessToken) {
			setLoggedIn(true);
		}
	}, [accessToken]);

	useEffect(() => {
		if (!loggedIn) {
			const redirectTimeout = setTimeout(() => {
				navigate('/login');
			}, 100);

			return () => clearTimeout(redirectTimeout);
		}
	}, [loggedIn, navigate]);

	useEffect(() => {
		const activeTabFromLocalStorage = localStorage.getItem('activeTab');
		const hash = location.hash || activeTabFromLocalStorage || '';
		setActiveTab(hash);
		setPageTitle(getPageTitleFromHash(hash));
	}, [location, t]);

	function openShowModal() {
		setShowModal(true);
	}

	function closeShowModal() {
		setShowModal(false);
	}

	function openShowModalAccount() {
		setShowAccount(true);
	}

	function closeShowModalAccount() {
		setShowAccount(false);
	}

	return (
		<section className="dashboard">
			<nav className="nav">
				{activeTab ? (
					<h1 className="nav__title">{t(pageTitle)}</h1>
				) : (
					<h1 className="nav__title">{t('control.pageTitle')}</h1>
				)}

				<span className="dashboard_lenguajes">
					<LenguajeAccordion />
				</span>

				{showModal && (
					<img
						src={close}
						alt="close"
						className="close"
						onClick={closeShowModal}
					/>
				)}

				{showModal && <LenguajeService />}

				{showAccount && (
					<Account
						userDetail={userDetail}
						close={closeShowModalAccount}
						handleLogout={onLogout}
					/>
				)}

				<span className="nav__uploads">
					<img src={add_circle} alt="add_circle" className="add_circle" />
					<img src={cloud} alt="cloud" className="cloud" />
					<img
						src={account}
						alt="account"
						className="account"
						onClick={openShowModalAccount}
					/>
					<img
						src={world}
						alt="world"
						className="world"
						onClick={openShowModal}
					/>
				</span>

				<span className="nav__account"></span>
			</nav>

			<div className="web_container">
				<div className="menu_desktop">
					<Bar
						activeTab={activeTab}
						handleTabClick={handleTabClick}
						setPageTitle={setPageTitle}
					/>
				</div>
				<div className="menu_mobile">
					<BarMobile
						activeTab={activeTab}
						handleTabClick={handleTabClick}
						setPageTitle={setPageTitle}
					/>
				</div>

				<div className="content">
					{activeTab === '#routes' && <Routes />}
					{activeTab === '#following' && <Following />}
					{activeTab === '#orders' && <Orders />}
					{activeTab === '#picking' && <Picking />}
					{activeTab === '#picking_box' && <PickingBox />}
					{activeTab === '#dashboard' && <Dashboard />}
					{activeTab === '#users' && <Users />}
					{activeTab === '#employees' && <Employees />}
					{activeTab === '#fleets' && <Fleets />}
					{activeTab === '#forecast' && <Forecast />}
					{activeTab === '#mrw' && <MRW />}
					{activeTab === '#mrw_multiple' && <MrwMultiple />}
					{activeTab === '#label_multiple' && <LabelMultiple />}
				</div>
			</div>
		</section>
	);
}
