import React from 'react';
import { Button, Input, Space } from 'antd';
import Search from 'antd/es/transfer/search';
import { SearchOutlined } from '@ant-design/icons';
import './main.css';
import { saveToLocalStorage } from '../../Login/storage';

export const InputOrderSearch = ({ handleInputOrderSearch, searchInput }) => {
	return (
		<Space
			direction="vertical"
			style={{ width: '100%', margin: '10px 0' }}
			size="large"
		>
			<Input
				placeholder="Search by reference"
				allowClear
				onChange={(e) => handleInputOrderSearch(e.target.value)}
				value={searchInput}
			/>
		</Space>
	);
};
