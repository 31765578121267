import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import {
	historySaveToLocalStorage,
	loadFromLocalStorage,
} from '../Login/storage';
import negative from '../../Assets/Sounds/negative.mp3';
import success from '../../Assets/Sounds/success.mp3';
import { Select, Table, Tag, Space, Button } from 'antd';
import './main.css';
import { useTranslation } from 'react-i18next';
import {
	API_URL_ORDER_STATUS_CHANGE,
	API_URL_PICKING_OPTIONS,
	get,
	post,
} from '../../App/Api';

const { Option } = Select;

export function Picking() {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [reference, setReference] = useState('');
	const [errorReferences, setErrorReferences] = useState([]);
	const [successReferences, setSuccessReferences] = useState([]);
	const [responseMessage, setResponseMessage] = useState(null);
	const [history, setHistory] = useState([]);
	const [count, setCount] = useState(0);
	const [playErrorSound] = useSound(negative);
	const [playSuccessSound] = useSound(success);

	// LOCALSTORAGE
	useEffect(() => {
		const savedHistory = loadFromLocalStorage('pickingHistory');
		if (savedHistory) {
			setHistory(savedHistory);
		}

		const storedErrorReferences = loadFromLocalStorage('errorReferences');
		if (Array.isArray(storedErrorReferences)) {
			setErrorReferences(storedErrorReferences);
		}

		const storedSuccessReferences = loadFromLocalStorage('successReferences');
		if (Array.isArray(storedSuccessReferences)) {
			setSuccessReferences(storedSuccessReferences);
		}

		const storedCount = localStorage.getItem('count');
		if (storedCount) {
			setCount(parseInt(storedCount));
		}
	}, []);

	const saveHistoryToLocalStorage = (newHistory) => {
		historySaveToLocalStorage('pickingHistory', newHistory);
	};

	const saveErrorReferencesToLocalStorage = (newErrorReferences) => {
		historySaveToLocalStorage('errorReferences', newErrorReferences);
	};

	const saveSuccessReferencesToLocalStorage = (newSuccessReferences) => {
		historySaveToLocalStorage('successReferences', newSuccessReferences);
	};

	// INCREMENTEO DEL ID
	const incrementCount = () => {
		setCount((prevCount) => prevCount + 1);
		localStorage.setItem('count', count + 1);
	};

	// RESET
	const resetCount = () => {
		setCount(0);
		localStorage.setItem('count', 0);
	};

	const optionChange = (value) => {
		setSelectedOption(value);
	};

	// REFERENCIAS
	const sendRequest = async () => {
		setReference('');

		if (!selectedOption) {
			playErrorSound();
			setErrorMessage(t('picking.errors.selectOption'));
			return;
		}

		const trimmedReference = reference.trim();

		if (history.some((entry) => entry.code.includes(trimmedReference))) {
			playErrorSound();
			setErrorMessage(t('picking.errors.referenceExists'));
			return;
		}

		setLoading(true);

		try {
			const response = await post(API_URL_ORDER_STATUS_CHANGE, {
				orderStatusCode: selectedOption,
				code: trimmedReference,
			});

			const newEntries = [];
			const successes = [];
			const errors = [];

			for (let index = 0; index < response.data.length; index++) {
				const order = response.data[index];
				const newEntry = {
					code: order.code,
					name:
						options.find((option) => option.orderStatusCode === selectedOption)
							?.name || '',
					message: order.message,
					error: order.error,
					number: count + 1,
				};

				if (order.error) {
					errors.unshift(newEntry);
				} else {
					successes.unshift(newEntry);
				}

				newEntries.unshift(newEntry);
			}

			const newHistory = newEntries.concat(history);
			setHistory(newHistory);
			saveHistoryToLocalStorage(newHistory);
			incrementCount();

			if (errors.length) {
				const newErrorReferences = errors.concat(errorReferences);
				setErrorReferences(newErrorReferences);
				saveErrorReferencesToLocalStorage(newErrorReferences);
			}

			if (successes.length) {
				const newSuccessReferences = successes.concat(successReferences);
				setSuccessReferences(newSuccessReferences);
				saveSuccessReferencesToLocalStorage(newSuccessReferences);
			}

			setResponseMessage({ error: errors.length, success: successes.length });
			setErrorMessage(null);
			setReference('');
		} catch (error) {
			setResponseMessage({ error: true });
			setErrorMessage(
				error?.response?.data?.message || t('picking.errors.requestError')
			);
		} finally {
			setLoading(false);
		}
	};

	// OPCIONES DEL SELECTED
	const fetchOptions = async () => {
		try {
			setLoading(true);
			const response = await get(API_URL_PICKING_OPTIONS);

			setOptions(response.data);
		} catch (error) {
			setErrorMessage(t('picking.errors.requestError'));
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOptions();
	}, [i18n.language]);

	// BORRAR REFERENCIAS
	const deleteAllErrors = () => {
		const newSuccessReferences = [...successReferences];

		setErrorReferences([]);
		saveErrorReferencesToLocalStorage([]);

		setHistory(newSuccessReferences);
		saveHistoryToLocalStorage(newSuccessReferences);
	};

	const deleteAllSuccess = () => {
		const newErrorReferences = [...errorReferences];
		setSuccessReferences([]);
		saveSuccessReferencesToLocalStorage([]);
		setHistory(newErrorReferences);
		saveHistoryToLocalStorage(newErrorReferences);
	};
	const deleteReference = (index, isError) => {
		const newSuccessReferences = [...successReferences];
		const newErrorReferences = [...errorReferences];

		if (isError) {
			newErrorReferences.splice(index, 1);
			setErrorReferences(newErrorReferences);
			saveErrorReferencesToLocalStorage(newErrorReferences);
		} else {
			newSuccessReferences.splice(index, 1);
			setSuccessReferences(newSuccessReferences);
			saveSuccessReferencesToLocalStorage(newSuccessReferences);
		}

		const newHistory = newSuccessReferences.concat(newErrorReferences);
		setHistory(newHistory);
		saveHistoryToLocalStorage(newHistory);
		resetCount();
	};

	// EVENTO DE LA TECLA ENTER
	const keyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendRequest();
		}
	};

	// SONIDOS
	useEffect(() => {
		if (responseMessage !== null) {
			if (responseMessage.error) {
				playErrorSound();
			} else {
				playSuccessSound();
			}
		}
	}, [responseMessage, playErrorSound, playSuccessSound]);

	const columnSuccess = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			width: 50,
			render: (text, record, index) => index + 1,
		},
		{
			title: t('columns.Reference'),
			dataIndex: 'code',
			key: 'code',
			width: 10,
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
			width: 50,
		},
		{
			title: t('columns.Action'),
			key: 'action',
			width: 50,
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteReference(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	const columnError = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			width: 10,
			render: (text, record, index) => index + 1,
		},
		{
			title: t('columns.Reference'),
			dataIndex: 'code',
			key: 'code',
			width: 10,
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
			width: 10,
		},
		{
			title: t('columns.Message'),
			dataIndex: 'message',
			key: 'message',
			width: 10,
		},
		{
			title: t('columns.Action'),
			key: 'action',
			width: 10,
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteReference(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	return (
		<div className="picking-container">
			<Select
				className="picking-select"
				style={{ width: '40%', margin: '10px' }}
				value={selectedOption}
				onChange={optionChange}
				onKeyDown={keyDown}
				showSearch
				filterOption={(inputValue, option) =>
					option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
				}
			>
				<Option value="">{t('picking.selectOption')}</Option>
				{options.map((option) => (
					<Option key={option.orderStatusCode} value={option.orderStatusCode}>
						{t(`${option.name}`)}
					</Option>
				))}
			</Select>

			<input
				type="text"
				className="picking-input"
				style={{ width: '40%', margin: '10px' }}
				value={reference}
				onChange={(e) => setReference(e.target.value)}
				placeholder={t('input.references')}
				onKeyDown={keyDown}
			/>

			{errorMessage && <p className="warning-message">{errorMessage}</p>}
			<div className="table-container">
				<div className="success-message">
					<Button
						type="primary"
						className="btn_primary"
						onClick={() => deleteAllSuccess()}
					>
						{t('columns.delete')}
					</Button>
					<Table
						className="ant-table-small"
						columns={columnSuccess}
						dataSource={successReferences}
					/>
				</div>

				<div className="error-message">
					<Button type="primary" onClick={() => deleteAllErrors()} danger>
						{t('columns.delete')}
					</Button>
					<Table
						className="ant-table-small"
						columns={columnError}
						dataSource={errorReferences}
					/>
				</div>
			</div>
		</div>
	);
}
