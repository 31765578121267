import React from 'react'
import { Button, Col, Collapse, Input, Row } from 'antd'
import { EmployeeFilterFleets } from '../../EmployeeFilterFleets';
import { FleetFiltersFleets } from '../../FleetsFilterFleets';
import { useTranslation } from 'react-i18next';
const { Panel } = Collapse;

export function AccordeonFiltersFleets({ filterProps }) {

    const { t } = useTranslation();

    return (
        <Collapse defaultActiveKey={[]} style={{ backgroundColor: '#fff', borderRadius: '8px', marginBottom: '1rem', width: '100%', margin: '0 auto' }}>
            <Panel header={t('accordeonFiltersFleets.filters')} key="1">
            <Row gutter={[16, 16]}>
                <Col span={8}>
                <p style={{ fontSize: '13px', margin: '5px 0' }}>{t('accordeonFiltersFleets.fleets')}</p>
                <FleetFiltersFleets {...filterProps}/>
                </Col>
                <Col span={8}>
                <p style={{ fontSize: '13px', margin: '5px 0' }}>{t('accordeonFiltersFleets.name')}</p>
                <EmployeeFilterFleets {...filterProps}/>
                </Col>
                <Col span={8}>
                <p style={{ fontSize: '13px', margin: '5px 0' }}>{t('accordeonFiltersFleets.city')}</p>
                </Col>
            </Row>
            <Row justify="end" align="middle" style={{ marginTop: '20px' }}>
                <Col>
                    <Button type="primary" onClick={filterProps.handleSearch} style={{ backgroundColor: '#142112', color: '#FFFFFF' }}>
                        {t('accordeonFiltersFleets.search')}
                    </Button>
                </Col>
            </Row>
        </Panel>
    </Collapse>
  )
}
