import React from 'react'
import logout from '../img/logout.svg';
import'./main.css'

export function Logout({ onLogout }) {
    const handleLogout = () => {
        onLogout();
    };

    return (
        <figure className='logout' onClick={handleLogout}>
            <button>logout</button>
        </figure>
    );
}
