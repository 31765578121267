import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useEffect, useState } from 'react';
import { API_URL_EMPLOYEES_SELECT, get } from '../../../App/Api';
import { useTranslation } from 'react-i18next';

export function EmployeeFilter({ handleEmployeeIds, employeeIds }) {
	const { t, i18n } = useTranslation();

	const [employees, setEmployees] = useState([]);

	useEffect(() => {
		fetchEmployees();
	}, [i18n.language]);

	const fetchEmployees = async () => {
		try {
			const response = await get(API_URL_EMPLOYEES_SELECT);
			setEmployees(response.data);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	return (
		<Select
			mode="multiple"
			onChange={handleEmployeeIds}
			defaultValue={employeeIds}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('orders.employeeFilter.Select employee IDs')}
		>
			{employees.map((employee) => (
				<Option key={employee.employeeId} value={employee.employeeId}>
					{employee.name}
				</Option>
			))}
		</Select>
	);
}
